
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { AGTestSuiteFeedbackConfig } from 'ag-client-typescript';

import Toggle from '@/components/toggle.vue';

@Component({
  components: {
    Toggle
  }
})
export default class AGTestSuiteAdvancedFdbkSettings extends Vue {
  @Prop({required: true, type: Object})
  value!: AGTestSuiteFeedbackConfig | null;

  d_feedback_config: AGTestSuiteFeedbackConfig | null = null;
  d_is_open = false;

  @Watch('value')
  on_value_changed(new_value: AGTestSuiteFeedbackConfig, old_value: AGTestSuiteFeedbackConfig) {
    this.d_feedback_config = JSON.parse(JSON.stringify(new_value));
  }

  toggle_is_open() {
    this.d_is_open = !this.d_is_open;
  }

  created() {
    this.d_feedback_config = JSON.parse(JSON.stringify(this.value));
  }
}
