
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Project, UltimateSubmissionPolicy } from 'ag-client-typescript';
import moment from "moment-timezone";

import APIErrors from '@/components/api_errors.vue';
import DatetimePicker from "@/components/datetime/datetime_picker.vue";
import TimePicker from "@/components/datetime/time_picker.vue";
import Modal from "@/components/modal.vue";
import Toggle from '@/components/toggle.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput, { ValidatorResponse } from '@/components/validated_input.vue';
import { handle_api_errors_async, make_error_handler_func } from '@/error_handling';
import { assert_not_null, deep_copy, format_datetime, format_datetime_short, format_time, toggle } from "@/utils";
import {
  is_integer,
  is_non_negative,
  is_not_empty,
  is_number,
  make_min_value_validator,
  string_to_num
} from '@/validators';

interface UltimateSubmissionPolicyOption {
  label: string;
  policy: UltimateSubmissionPolicy;
}

@Component({
  components: {
    APIErrors,
    DatetimePicker,
    Modal,
    TimePicker,
    Toggle,
    Tooltip,
    ValidatedForm,
    ValidatedInput,
  }
})
export default class ProjectSettings extends Vue {
  @Prop({required: true, type: Project})
  project!: Project;

  get timezones() {
    return moment.tz.names();
  }

  d_project: Project | null = null;
  d_saving = false;
  settings_form_is_valid = true;

  d_show_reset_time_picker = false;

  d_show_delete_project_modal = false;
  d_deleting = false;

  readonly is_non_negative = is_non_negative;
  readonly is_not_empty = is_not_empty;
  readonly is_number = is_number;
  readonly is_integer = is_integer;
  readonly string_to_num = string_to_num;
  readonly is_positive_int_or_empty_str = is_positive_int_or_empty_str;
  readonly is_positive = make_min_value_validator(1);

  readonly UltimateSubmissionPolicy = UltimateSubmissionPolicy;

  readonly format_datetime = format_datetime;
  readonly format_datetime_short = format_datetime_short;
  readonly format_time = format_time;

  async created() {
    this.d_project = deep_copy(this.project, Project);
  }

  @handle_api_errors_async(handle_save_project_settings_error)
  async save_project_settings() {
    try {
      assert_not_null(this.d_project);
      this.d_saving = true;
      (<APIErrors> this.$refs.api_errors).clear();

      await this.d_project.save();
    }
    finally {
      this.d_saving = false;
    }
  }

  @handle_api_errors_async(make_error_handler_func('delete_errors'))
  async delete_project() {
    return toggle(this, 'd_deleting', async () => {
      await this.project.delete();
      return this.$router.push({
        name: 'course_admin',
        params: {course_id: this.project.course.toString()},
        query: {current_tab: 'projects'}
      });
    });
  }
}

export function handle_save_project_settings_error(component: ProjectSettings, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}

function is_positive_int_or_empty_str(value: string): ValidatorResponse {
  value = value.trim();
  if (value === '') {
    return {is_valid: true, error_msg: ''};
  }

  return make_min_value_validator(1)(value);
}

