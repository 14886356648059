
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
    AGTestCaseResultFeedback,
    AGTestSuiteResultFeedback,
    FeedbackCategory,
    Submission
} from "ag-client-typescript";

import AGTestCaseResultDetail from '@/components/project_view/submission_detail/ag_test_case_result_detail.vue';
import AGTestSuiteSetupResultDetail from '@/components/project_view/submission_detail/ag_test_suite_setup_result_detail.vue';
import {
  ag_test_case_result_correctness,
  ag_test_case_result_output_correctness,
  ag_test_case_result_return_code_correctness,
  CorrectnessLevel,
  setup_return_code_correctness
} from "@/components/project_view/submission_detail/correctness";
import ResultPanel from "@/components/project_view/submission_detail/result_panel.vue";


@Component({
  components: {
    AGTestCaseResultDetail,
    AGTestSuiteSetupResultDetail,
    ResultPanel
  }
})
export default class AGTestSuiteResultDetail extends Vue {

  @Prop({required: true, type: Submission})
  submission!: Submission;

  @Prop({required: true, type: Object})
  ag_test_suite_result!: AGTestSuiteResultFeedback;

  @Prop({required: true, type: String})
  fdbk_category!: FeedbackCategory;

  @Prop({default: false, type: Boolean})
  is_first_suite!: boolean;

  readonly CorrectnessLevel = CorrectnessLevel;

  private get setup_correctness_level(): CorrectnessLevel {
    if (this.ag_test_suite_result.setup_name === null) {
      return CorrectnessLevel.not_available;
    }
    if (this.ag_test_suite_result.setup_return_code === null
        && this.ag_test_suite_result.setup_timed_out === null) {
      return CorrectnessLevel.info_only;
    }
    return setup_return_code_correctness(this.ag_test_suite_result.setup_return_code,
                                         this.ag_test_suite_result.setup_timed_out);
  }

  private get first_incorrect_case(): AGTestCaseResultFeedback | null {
    if (!this.is_first_suite || this.setup_correctness_level === CorrectnessLevel.none_correct) {
      return null;
    }

    for (let ag_case of this.ag_test_suite_result.ag_test_case_results) {
      let case_correctness = this.case_result_correctness(ag_case);
      if (case_correctness === CorrectnessLevel.some_correct
          || case_correctness === CorrectnessLevel.none_correct) {
        return ag_case;
      }
    }
    return null;
  }

  case_result_correctness(case_result: AGTestCaseResultFeedback) {
    return ag_test_case_result_correctness(case_result);
  }

  case_result_return_code_correctness(case_result: AGTestCaseResultFeedback) {
    return ag_test_case_result_return_code_correctness(case_result);
  }

  case_result_output_correctness(case_result: AGTestCaseResultFeedback) {
    return ag_test_case_result_output_correctness(case_result);
  }
}
