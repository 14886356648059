import { render, staticRenderFns } from "./manage_projects.vue?vue&type=template&id=6a009043&scoped=true&"
import script from "./manage_projects.vue?vue&type=script&lang=ts&"
export * from "./manage_projects.vue?vue&type=script&lang=ts&"
import style0 from "./manage_projects.vue?vue&type=style&index=0&id=6a009043&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a009043",
  null
  
)

export default component.exports