
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  AGTestSuite,
  ExpectedStudentFile,
  InstructorFile,
  MutationTestSuite,
  Project,
  SandboxDockerImageData,
} from 'ag-client-typescript';

import BatchSelect from '@/components/batch_select.vue';
import DropdownTypeahead from '@/components/dropdown_typeahead.vue';
import SelectObject from '@/components/select_object.vue';
import Toggle from '@/components/toggle.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { is_not_empty } from '@/validators';
import { deep_copy } from '@/utils';

class Suite {
  name: string;
  sandbox_docker_image: SandboxDockerImageData;
  allow_network_access: boolean;
  deferred: boolean;
  instructor_files_needed: InstructorFile[];
  read_only_instructor_files: boolean;
  student_files_needed: ExpectedStudentFile[];

  constructor(args: Suite) {
    this.name = args.name;
    this.sandbox_docker_image = args.sandbox_docker_image;
    this.allow_network_access = args.allow_network_access;
    this.deferred = args.deferred;
    this.instructor_files_needed = args.instructor_files_needed;
    this.read_only_instructor_files = args.read_only_instructor_files;
    this.student_files_needed = args.student_files_needed;
  }
}

@Component({
  components: {
    DropdownTypeahead,
    BatchSelect,
    SelectObject,
    Toggle,
    Tooltip,
    ValidatedInput,
  }
})
export default class SuiteSettings extends Vue {
  @Prop({required: true})
  suite!: Suite;

  @Prop({required: true, type: Project})
  project!: Project;

  @Prop({required: true})
  docker_images!: SandboxDockerImageData[];

  d_suite: Suite | null = null;

  readonly is_not_empty = is_not_empty;

  created() {
    this.d_suite = new Suite(this.suite);
  }

  @Watch('suite', {deep: true})
  on_suite_changed(new_value: Suite, old_value: Suite) {
    this.d_suite = deep_copy(new_value, Suite);
  }

  add_instructor_file(instructor_file: InstructorFile) {
    this.d_suite!.instructor_files_needed.push(instructor_file);
    this.$emit('field_change', this.d_suite);
  }

  add_student_file(student_file: ExpectedStudentFile) {
    this.d_suite!.student_files_needed.push(student_file);
    this.$emit('field_change', this.d_suite);
  }

  are_files_equal(
    rhs: InstructorFile | ExpectedStudentFile,
    lhs: InstructorFile | ExpectedStudentFile
  ) {
    return lhs.pk === rhs.pk;
  }

  instructor_file_filter_fn(file: InstructorFile, filter_text: string) {
    return file.name.toLowerCase().indexOf(filter_text.toLowerCase()) >= 0;
  }

  expected_student_file_filter_fn(
    file: ExpectedStudentFile,
    filter_text: string
  ) {
    return file.pattern.toLowerCase().indexOf(filter_text.toLowerCase()) >= 0;
  }

  get instructor_files_available() {
    return this.project.instructor_files!.filter((instructor_file: InstructorFile) => {
      return this.d_suite!.instructor_files_needed.findIndex(
        (file: InstructorFile) => file.pk === instructor_file.pk) === -1;
    });
  }

  get expected_student_files_available() {
    return this.project.expected_student_files.filter(
      (expected_student_file: ExpectedStudentFile) => {
        return this.d_suite!.student_files_needed.findIndex(
          (file: ExpectedStudentFile) => file.pk === expected_student_file.pk) === -1;
      }
    );
  }
}
