
import { Component, Inject, Vue } from 'vue-property-decorator';

import {
  ExpectedStudentFile,
  ExpectedStudentFileObserver,
  InstructorFile,
  InstructorFileObserver,
  Project,
  ProjectObserver,
} from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import { ArraySet } from '@/array_set';
import AGTestSuites from '@/components/project_admin/ag_tests/ag_test_suites.vue';
import DownloadGrades from "@/components/project_admin/download_grades.vue";
import EditGroups from '@/components/project_admin/edit_groups/edit_groups.vue';
import ExpectedStudentFiles from '@/components/project_admin/expected_student_files/expected_student_files.vue';
import HandgradingSettings from '@/components/project_admin/handgrading_settings/handgrading_settings.vue';
import InstructorFiles from '@/components/project_admin/instructor_files/instructor_files.vue';
import MutationSuites from '@/components/project_admin/mutation_suites/mutation_suites.vue';
import ProjectSettings from '@/components/project_admin/project_settings.vue';
import ProjectStats from '@/components/project_admin/project_stats/project_stats.vue';
import RerunSubmissions from '@/components/project_admin/rerun_submissions/rerun_submissions.vue';
import { CurrentTabMixin } from '@/current_tab_mixin';
import { handle_global_errors_async } from '@/error_handling';
import { BeforeDestroy, Created, Destroyed, Mounted } from '@/lifecycle';
import { assert_not_null, deep_copy, get_query_param, safe_assign } from "@/utils";

@Component({
  components: {
    'ag-test-suites': AGTestSuites,
    DownloadGrades,
    EditGroups,
    ExpectedStudentFiles,
    HandgradingSettings,
    InstructorFiles,
    MutationSuites,
    ProjectSettings,
    ProjectStats,
    RerunSubmissions,
  }
})
export default class ProjectAdmin extends CurrentTabMixin implements ProjectObserver,
                                                          InstructorFileObserver,
                                                          ExpectedStudentFileObserver,
                                                          Created,
                                                          Mounted,
                                                          BeforeDestroy {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  d_loading = true;
  d_project: Project | null = null;

  @handle_global_errors_async
  async created() {
    this.d_project = await Project.get_by_pk(Number(this.$route.params.project_id));

    await this.d_globals.set_current_project(this.d_project);
    Project.subscribe(this);
    InstructorFile.subscribe(this);
    ExpectedStudentFile.subscribe(this);
    this.d_loading = false;
  }

  mounted() {
    return this.initialize_current_tab('settings');
  }

  beforeDestroy() {
    Project.unsubscribe(this);
    InstructorFile.unsubscribe(this);
    ExpectedStudentFile.unsubscribe(this);
  }

  update_project_created(project: Project): void {
  }

  update_project_changed(project: Project): void {
    if (project.pk === this.d_project!.pk) {
      this.d_project = deep_copy(project, Project);
    }
  }

  update_instructor_file_created(instructor_file: InstructorFile) {
    if (this.d_project === null || instructor_file.project !== this.d_project.pk) {
      return;
    }
    assert_not_null(this.d_project.instructor_files);
    this.d_project.instructor_files.push(instructor_file);
    this.d_project.instructor_files.sort(
      (first: InstructorFile, second: InstructorFile) => first.name.localeCompare(second.name)
    );
  }

  update_instructor_file_deleted(instructor_file: InstructorFile) {
    if (this.d_project === null || instructor_file.project !== this.d_project.pk) {
      return;
    }
    assert_not_null(this.d_project.instructor_files);
    this.d_project.instructor_files.splice(
      this.d_project.instructor_files.findIndex(file => file.pk === instructor_file.pk),
      1
    );
  }

  update_instructor_file_renamed(instructor_file: InstructorFile) {
    if (this.d_project === null || instructor_file.project !== this.d_project.pk) {
      return;
    }

    assert_not_null(this.d_project.instructor_files);
    let index = this.d_project.instructor_files.findIndex(
      (file) => file.pk === instructor_file.pk
    );
    Vue.set(this.d_project.instructor_files, index, instructor_file);

    this.d_project.instructor_files.sort(
      (first: InstructorFile, second: InstructorFile) => first.name.localeCompare(second.name)
    );
  }

  update_expected_student_file_created(expected_student_file: ExpectedStudentFile): void {
    if (this.d_project === null || expected_student_file.project !== this.d_project.pk) {
      return;
    }

    this.d_project.expected_student_files.push(expected_student_file);
    this.d_project.expected_student_files.sort(
      (first: ExpectedStudentFile, second: ExpectedStudentFile) => {
        return first.pattern.localeCompare(second.pattern);
      }
    );
  }

  update_expected_student_file_changed(expected_student_file: ExpectedStudentFile): void {
    if (this.d_project === null || expected_student_file.project !== this.d_project.pk) {
      return;
    }

    let index = this.d_project.expected_student_files.findIndex(
      (file) => file.pk === expected_student_file.pk);
    Vue.set(this.d_project.expected_student_files, index, expected_student_file);

    this.d_project.expected_student_files.sort(
      (first: ExpectedStudentFile, second: ExpectedStudentFile) => {
        return first.pattern.localeCompare(second.pattern);
      }
    );
  }

  update_expected_student_file_deleted(expected_student_file: ExpectedStudentFile): void {
    if (this.d_project === null || expected_student_file.project !== this.d_project.pk) {
      return;
    }

    this.d_project.expected_student_files.splice(
      this.d_project.expected_student_files.findIndex(item => item.pk === expected_student_file.pk),
      1
    );
  }

  update_instructor_file_content_changed(
    instructor_file: InstructorFile, new_content: Blob): void {}
}
