
  import { Component, Vue } from 'vue-property-decorator';

  import Toggle from '@/components/toggle.vue';

  @Component({
    components: { Toggle }
  })
  export default class ToggleDemo extends Vue {

    toggle_ex_1_value = true;

    toggle_ex_1_active_color = 'hsl(46, 100%, 66%)';

    toggle_ex_2_value = false;

  }
