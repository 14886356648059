
import { Component, Prop, Vue } from 'vue-property-decorator';

import * as ag_cli from 'ag-client-typescript';

import { ArraySet, pk_more } from '@/array_set';
import { handle_global_errors_async } from '@/error_handling';
import { format_datetime } from '@/utils';

@Component
export default class SubmissionSelector extends Vue {
  @Prop({required: true, type: ag_cli.Group})
  group!: ag_cli.Group;

  d_submissions: ag_cli.Submission[] = [];
  d_selected_submissions = new ArraySet<ag_cli.Submission>([], {less_func: pk_more});

  d_loading = true;
  d_collapsed = false;

  readonly format_datetime = format_datetime;

  @handle_global_errors_async
  async created() {
    this.d_submissions = await ag_cli.Submission.get_all_from_group(this.group.pk);
    this.d_loading = false;
  }

  toggle_submission(submission: ag_cli.Submission) {
    if (this.d_selected_submissions.has(submission)) {
      this.d_selected_submissions.remove(submission);
      this.$emit('submissions_unselected', [submission]);
    }
    else {
      this.d_selected_submissions.insert(submission);
      this.$emit('submissions_selected', [submission]);
    }
  }

  select_all() {
    this.d_selected_submissions = new ArraySet(this.d_submissions.slice(), {less_func: pk_more});
    this.$emit('submissions_selected', this.d_selected_submissions.data);
  }

  clear_all() {
    let to_emit = this.d_selected_submissions.data;
    this.d_selected_submissions = new ArraySet([], {less_func: pk_more});
    this.$emit('submissions_unselected', to_emit);
  }
}
