
import { Component, Prop, Vue } from 'vue-property-decorator';

import { GradingStatus, Submission, SubmissionWithResults } from 'ag-client-typescript';

import { format_datetime_short } from '@/utils';

@Component
export default class SubmissionPanel extends Vue {
  @Prop({required: true})
  submission!: SubmissionWithResults;

  readonly GradingStatus = GradingStatus;
  readonly format_datetime_short = format_datetime_short;
}
