
import { Component, Prop, Vue } from 'vue-property-decorator';

import * as ag_cli from 'ag-client-typescript';

import APIErrors from "@/components/api_errors.vue";
import Modal from '@/components/modal.vue';
import Tooltip from '@/components/tooltip.vue';
import { SYSADMIN_CONTACT } from '@/constants';
import { handle_api_errors_async, handle_global_errors_async, make_error_handler_func } from '@/error_handling';
import { format_datetime, safe_assign, toggle } from '@/utils';

@Component({
  components: {
    APIErrors,
    Modal,
    Tooltip,
  }
})
export default class RerunTaskDetail extends Vue {
  @Prop({required: true, type: ag_cli.RerunSubmissionTask})
  task!: ag_cli.RerunSubmissionTask;

  d_show_cancel_modal = false;
  d_cancelling = false;

  readonly format_datetime = format_datetime;
  readonly SYSADMIN_CONTACT = SYSADMIN_CONTACT;

  @handle_global_errors_async
  async refresh_task(task: ag_cli.RerunSubmissionTask) {
    let refreshed = await ag_cli.RerunSubmissionTask.get_by_pk(task.pk);
    safe_assign(task, refreshed);
  }

  @handle_api_errors_async(make_error_handler_func())
  cancel_task() {
    return toggle(this, 'd_cancelling', async () => {
        await this.task.cancel();
        this.d_show_cancel_modal = false;
    });
  }
}
