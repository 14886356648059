
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Collapsible extends Vue {
  @Prop({default: false, type: Boolean})
  start_open!: boolean;

  @Prop({default: true, type: Boolean})
  include_caret!: boolean;

  // When true, this collapsible will stay open even when clicked on.
  // Once this value becomes false, it will be able to close again.
  @Prop({default: false, type: Boolean})
  stay_open!: boolean;

  // When true, the collapsible's header panel will be highlighted to
  // indicate that it is active.
  @Prop({default: false, type: Boolean})
  is_active!: boolean;

  // Used to configure styling of the collapsible's header panel indentation level.
  // Note that it does not affect the indentation of the collapsible's
  // body.
  // Valid values are null and the numbers 0, 1, 2, and 3.
  // Note that indentation level 0 adds a small amount of visual left padding,
  // while null adds no left padding.
  @Prop({
    default: 0,
    type: Number,
    validator: val => val === null || (<number> val >= 0 && <number> val <= 3)
  })
  indentation_level!: number;

  // When true, the header_icons_slot will always be visible.
  // When false, the header_icons slot will only be visible when the user
  // hovers over the header panel or when this.is_active is true.
  @Prop({default: false, type: Boolean})
  always_show_icons!: boolean;

  // When true, the body of the collapsible will be wrapped
  // in a v-if instead of v-show.
  @Prop({default: false, type: Boolean})
  use_v_if!: boolean;

  private d_is_open = this.start_open;

  toggle_is_open() {
    if (this.d_is_open && !this.stay_open) {
      this.d_is_open = false;
    }
    else {
      this.d_is_open = true;
    }
  }
}
