
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import APIErrors from '@/components/api_errors.vue';
import { handle_api_errors_async, make_error_handler_func } from '@/error_handling';
import { assert_not_null, toggle } from '@/utils';
import { display_mutant_name, MutantHintService, UnlockedHintData } from './mutant_hint_service';

@Component({
  components: {
    APIErrors
  }
})
export default class UnlockedHint extends Vue {
  @Prop({required: true, type: Object})
  hint!: UnlockedHintData;

  d_hint_rating: number | null = null;
  d_user_comment: string = '';
  d_saving = false;

  d_radio_button_random_num = Math.floor(Math.random() * 1000000);

  readonly display_mutant_name = display_mutant_name;

  @Watch('hint', {deep: true})
  on_hint_change(new_value: UnlockedHintData, old_value: UnlockedHintData) {
    this.d_hint_rating = new_value.hint_rating;
    this.d_user_comment = new_value.user_comment;
  }

  @handle_api_errors_async(make_error_handler_func())
  rate_hint() {
    return toggle(this, 'd_saving', () => {
      assert_not_null(this.d_hint_rating);
      (<APIErrors> this.$refs.api_errors).clear();
      return MutantHintService.rate_hint(
        this.hint.pk,
        {hint_rating: this.d_hint_rating, user_comment: this.d_user_comment}
      );
    });
  }
}
