
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Course, Semester } from 'ag-client-typescript';

import APIErrors from "@/components/api_errors.vue";
import Modal from '@/components/modal.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async, make_error_handler_func } from '@/error_handling';
import {
  deep_copy,
  format_datetime_short,
  safe_assign,
  toggle
} from '@/utils';
import {
  is_integer,
  is_non_negative,
  is_not_empty,
  is_number,
  make_min_value_validator,
  string_to_num
} from '@/validators';

import CourseForm, { CourseFormData } from './course_form.vue';

@Component({
  components: {
    APIErrors,
    CourseForm,
    Modal,
    Tooltip,
    ValidatedForm,
    ValidatedInput
  }
})
export default class CourseSettings extends Vue {
  @Prop({required: true, type: Course})
  course!: Course;

  d_course: Course = new Course({
    pk: 0,
    name: '',
    semester: Semester.fall,
    year: 0,
    subtitle: '',
    num_late_days: 0,
    allowed_guest_domain: '',
    last_modified: '',
  });

  d_saving = false;
  readonly semesters = [Semester.fall, Semester.winter, Semester.spring, Semester.summer];
  d_settings_form_is_valid = true;

  d_show_delete_course_modal = false;
  d_deleting = false;

  readonly Semester = Semester;

  readonly is_non_negative = is_non_negative;
  readonly is_not_empty = is_not_empty;
  readonly is_integer = is_integer;
  readonly is_number = is_number;
  readonly is_valid_course_year = make_min_value_validator(2000);
  readonly string_to_num = string_to_num;

  readonly format_datetime_short = format_datetime_short;

  created() {
    this.d_course = deep_copy(this.course, Course);
  }

  @handle_api_errors_async(make_error_handler_func())
  save_course_settings(form_data: CourseFormData) {
    return toggle(this, 'd_saving', () => {
      safe_assign(this.d_course, form_data);
      return this.d_course.save();
    });
  }

  @handle_api_errors_async(make_error_handler_func('delete_errors'))
  delete_course() {
    return toggle(this, 'd_deleting', async () => {
      await this.course.delete();
      return this.$router.push({name: 'course_list'});
    });
  }
}
