
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import {
  AGTestCase,
  AGTestCommand,
  AGTestSuite
} from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import Modal from '@/components/modal.vue';
import AGTestCasePanel from '@/components/project_admin/ag_tests/ag_test_case_panel.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput, { ValidatorResponse } from '@/components/validated_input.vue';
import { handle_api_errors_async, handle_global_errors_async } from '@/error_handling';
import { is_not_empty } from '@/validators';

export class NewCommandFields {
  name: string;
  cmd: string;

  constructor({name = "", cmd = ""}: {name?: string, cmd?: string}) {
    this.name = name;
    this.cmd = cmd;
  }
}

@Component({
  components: {
    AGTestCasePanel,
    APIErrors,
    Draggable,
    Modal,
    Tooltip,
    ValidatedForm,
    ValidatedInput
  }
})
export default class AGTestSuitePanel extends Vue {

  @Prop({default: null, type: AGTestSuite})
  active_ag_test_suite!: AGTestSuite | null;

  @Prop({required: true})
  active_ag_test_command!: AGTestCommand | null;

  @Prop({required: true, type: AGTestSuite})
  ag_test_suite!: AGTestSuite;

  d_show_new_ag_test_case_modal = false;
  d_add_case_form_is_valid = false;
  d_cases_are_visible = false;
  d_creating_case = false;
  d_duplicate_command_name_in_case = false;
  d_new_case_name = "";
  d_new_commands: NewCommandFields[] = [new NewCommandFields({})];

  readonly is_not_empty = is_not_empty;

  @Watch('active_ag_test_command')
  on_active_ag_test_command_changed(new_active_ag_test_command: AGTestCommand,
                                    old_active_ag_test_command: AGTestCommand) {
    if (this.command_in_suite_is_active) {
      this.d_cases_are_visible = true;
    }
  }

  get command_in_suite_is_active() {
    return this.active_ag_test_command !== null && this.ag_test_suite.ag_test_cases.findIndex(
      (test_case: AGTestCase) => test_case.pk === this.active_ag_test_command!.ag_test_case
    ) !== -1;
  }

  get is_open() {
    return this.d_cases_are_visible;
  }

  format_ordinal_num(index: number) {
    if (index === 0) {
      return "First";
    }
    return index === 1 ? "Second" : "Third";
  }

  update_ag_test_suite_panel_when_clicked() {
    if (!this.d_cases_are_visible) {
      this.d_cases_are_visible = true;
      this.$emit('update_active_item', this.ag_test_suite);
    }
    else {
      if (this.suite_is_active) {
        this.d_cases_are_visible = false;
      }
      else if (this.command_in_suite_is_active) {
        this.$emit('update_active_item', this.ag_test_suite);
      }
      else {
        this.$emit('update_active_item', this.ag_test_suite);
      }
    }
  }

  add_command() {
    if (this.d_new_commands.length === 1) {
      this.d_new_commands[0].name = this.d_new_case_name;
    }
    this.d_new_commands.push(new NewCommandFields({}));
  }

  remove_command(index: number) {
    this.d_new_commands.splice(index, 1);
    if (this.d_new_commands.length === 1) {
      this.d_duplicate_command_name_in_case = false;
    }
  }

  get suite_is_active() {
    return this.active_ag_test_suite !== null
           && this.active_ag_test_suite.pk === this.ag_test_suite.pk;
  }

  open_new_ag_test_case_modal() {
    this.$emit('update_active_item', this.ag_test_suite);
    this.d_duplicate_command_name_in_case = false;
    this.d_new_case_name = "";
    this.d_show_new_ag_test_case_modal = true;
    Vue.nextTick(() => {
      (<ValidatedInput> this.$refs.new_case_name).focus();
    });
  }

  get duplicate_command_name(): string {
    let names = new Set();
    for (let new_command of this.d_new_commands) {
      if (names.has(new_command.name)) {
        return new_command.name;
      }
      names.add(new_command.name);
    }

    return "";
  }

  @handle_global_errors_async
  set_ag_test_case_order() {
    return AGTestCase.update_order(
      this.ag_test_suite.pk, this.ag_test_suite.ag_test_cases.map(test_case => test_case.pk));
  }

  @handle_api_errors_async(handle_create_ag_test_case_error)
  async create_ag_test_case() {
    try {
      this.d_creating_case = true;
      this.d_duplicate_command_name_in_case = false;

      if (this.d_new_commands.length === 1) {
        this.d_new_commands[0].name = this.d_new_case_name;
      }
      else {
        if (this.duplicate_command_name !== "") {
          this.d_duplicate_command_name_in_case = true;
          return;
        }
      }

      (<APIErrors> this.$refs.new_ag_test_case_api_errors).clear();
      let created_case = await AGTestCase.create(
        this.ag_test_suite!.pk, {name: this.d_new_case_name}
      );

      for (let i = 0; i < this.d_new_commands.length; ++i) {
        await AGTestCommand.create(
          created_case.pk, {name: this.d_new_commands[i].name, cmd: this.d_new_commands[i].cmd}
        );
      }
      (<ValidatedForm> this.$refs.create_ag_test_case_form).reset_warning_state();
      this.d_show_new_ag_test_case_modal = false;
    }
    finally {
      this.d_creating_case = false;
    }
  }
}

function handle_create_ag_test_case_error(component: AGTestSuitePanel, error: unknown) {
  (<APIErrors> component.$refs.new_ag_test_case_api_errors).show_errors_from_response(error);
}
