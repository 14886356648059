
import { Component, Prop, Vue } from 'vue-property-decorator';

import Diff from '@/components/diff.vue';

@Component({
  components: {Diff}
})
export default class DiffDemo extends Vue {

  balanced_diff = Promise.resolve([
    '  one\r\n',
    '  two\n',
    '- left one\n',
    '- left two\n',
    '- left three\n',
    '  three\n',
    '+ right one\n',
    '+ right two\n',
    '  four\n',
    '  five\n'
  ]);

  wide_diff = Promise.resolve([
    "  ./euchre.exe pack.in noshuffle 1 Alice Simple Bob Simple Cathy Simple Drew Simple\n",
    "- Hand 0\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "+ Hand 0\n",
  ]);

  diff_no_right = Promise.resolve([
    "-  ./euchre.exe pack.in noshuffle 1 Alice Simple Bob Simple Cathy Simple Drew Simple\n",
    "- Hand 0\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Jack of Diamonds turned up\n",
    "- Hand 0\n",
  ]);

  diff_empty = Promise.resolve([]);
}
