
import { Component, Prop, Vue } from 'vue-property-decorator';

import * as ag_cli from 'ag-client-typescript';
import { assert_not_null } from '@/utils';

@Component({})
export default class ScoreTable extends Vue {
  @Prop({type: Array, required: true})
  submission_results: ag_cli.FullUltimateSubmissionResult[] | null = null;

  get first_non_null_submission_results() {
    assert_not_null(this.submission_results);
    let first_non_null = this.submission_results.find(res => res.ultimate_submission !== null);
    return first_non_null;
  }

  get_ag_test_suite_results(submission_result: ag_cli.FullUltimateSubmissionResult) {
    if (submission_result.ultimate_submission === null) {
      return [];
    }
    return submission_result.ultimate_submission.results.ag_test_suite_results;
  }

  get_mutation_test_suite_results(submission_result: ag_cli.FullUltimateSubmissionResult) {
    if (submission_result.ultimate_submission === null) {
      return [];
    }
    return submission_result.ultimate_submission.results.mutation_test_suite_results;
  }
}
