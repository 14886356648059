import { render, staticRenderFns } from "./file_upload.vue?vue&type=template&id=60ff0dd8&scoped=true&"
import script from "./file_upload.vue?vue&type=script&lang=ts&"
export * from "./file_upload.vue?vue&type=script&lang=ts&"
import style0 from "./file_upload.vue?vue&type=style&index=0&id=60ff0dd8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60ff0dd8",
  null
  
)

export default component.exports