
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { Course, Group, GroupInvitation, Project, User } from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import APIErrors from '@/components/api_errors.vue';
import GroupMembersForm from '@/components/group_members_form.vue';
import Modal from '@/components/modal.vue';
import { GroupMember } from "@/components/project_admin/edit_groups/create_single_group.vue";
import InvitationReceived from '@/components/project_view/group_registration/invitation_received.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async, handle_global_errors_async } from '@/error_handling';

@Component({
  components: {
    APIErrors,
    GroupMembersForm,
    InvitationReceived,
    Modal,
    ValidatedForm,
    ValidatedInput
  }
})
export default class GroupRegistration extends Vue {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  @Prop({required: true, type: Project})
  project!: Project;

  @Prop({required: true, type: Course})
  course!: Course;

  d_awaiting_action = false;
  d_deleting_invitation = false;
  d_loading = true;
  d_sending_invitation = false;
  invitation_sent: GroupInvitation | null = null;
  invitations_received: GroupInvitation[] = [];
  users_to_invite: GroupMember[] = [];
  d_show_confirm_working_alone_modal = false;
  d_show_send_group_invitation_modal = false;
  d_show_delete_invitation_modal = false;

  @handle_global_errors_async
  async created() {
    if (this.project.disallow_group_registration) {
      this.d_loading = false;
      return;
    }
    if (this.project.max_group_size === 1) {
      await this.work_alone();
      this.d_loading = false;
      return;
    }

    this.invitations_received = await this.d_globals.current_user!.group_invitations_received();
    this.invitations_received = this.invitations_received.filter(
      (group_invitation: GroupInvitation) => group_invitation.project === this.project.pk
    );

    let invitations_sent = await this.d_globals.current_user!.group_invitations_sent();
    invitations_sent = invitations_sent.filter(
      (group_invitation: GroupInvitation) => group_invitation.project === this.project.pk
    );

    if (invitations_sent.length > 0) {
      this.invitation_sent = invitations_sent[0];
    }

    this.d_loading = false;
  }

  @handle_api_errors_async(handle_delete_invitation_error)
  async delete_invitation() {
    try {
      this.d_deleting_invitation = true;
      (<APIErrors> this.$refs.delete_invitation_api_errors).clear();
      await this.invitation_sent!.reject();
      this.invitation_sent = null;
      this.d_show_delete_invitation_modal = false;
    }
    finally {
      this.d_deleting_invitation = false;
    }
  }

  invitee_acceptance_status(username: string) {
    return this.invitation_sent!.recipients_who_accepted.findIndex(
        (name: string) => username === name) !== -1 ? 'Accepted' : 'Pending';
  }

  @handle_api_errors_async(handle_send_invitation_error)
  async send_invitation(usernames: string[]) {
    try {
      this.d_sending_invitation = true;
      (<APIErrors> this.$refs.send_invitation_api_errors).clear();
      this.invitation_sent = await GroupInvitation.send_invitation(this.project.pk, usernames);
      this.d_show_send_group_invitation_modal = false;
    }
    finally {
      this.d_sending_invitation = false;
    }
  }

  @handle_api_errors_async(handle_work_alone_error)
  async work_alone() {
    try {
      this.d_awaiting_action = true;
      await Group.create_solo_group(this.project.pk);
    }
    finally {
      this.d_awaiting_action = false;
    }
  }
}

function handle_work_alone_error(component: GroupRegistration, error: unknown) {
  (<APIErrors> component.$refs.work_alone_api_errors).show_errors_from_response(error);
}

function handle_delete_invitation_error(component: GroupRegistration, error: unknown) {
  (<APIErrors> component.$refs.delete_invitation_api_errors).show_errors_from_response(error);
}

function handle_send_invitation_error(component: GroupRegistration, error: unknown) {
  (<APIErrors> component.$refs.send_invitation_api_errors).show_errors_from_response(error);
}
