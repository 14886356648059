

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  AGTestCase,
  AGTestCommand,
  AGTestCommandFeedbackConfig,
  ExpectedOutputSource,
  ExpectedReturnCode,
  Project,
  StdinSource,
  ValueFeedbackLevel,
} from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import Dropdown from '@/components/dropdown.vue';
import Modal from '@/components/modal.vue';
import AGTestCommandAdvancedFdbkSettings from '@/components/project_admin/ag_tests/ag_test_command_advanced_fdbk_settings.vue';
import {
  AGTestCommandFeedbackPreset,
  FeedbackConfigLabel,
  FeedbackDescriptions,
} from '@/components/project_admin/feedback_config_panel/feedback_config_utils';
import ResourceLimitSettings from '@/components/project_admin/resource_limit_settings.vue';
import SelectObject from '@/components/select_object.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import {
  handle_api_errors_async,
  handle_global_errors_async,
  make_error_handler_func
} from '@/error_handling';
import { SafeMap } from '@/safe_map';
import { deep_copy, format_datetime, toggle } from '@/utils';
import {
  is_integer,
  is_not_empty,
  make_max_value_validator,
  make_min_value_validator,
  string_to_num,
} from '@/validators';

import FeedbackConfigPanel from '../feedback_config_panel/feedback_config_panel.vue';

@Component({
  components: {
    APIErrors,
    FeedbackConfigPanel,
    Dropdown,
    AGTestCommandAdvancedFdbkSettings,
    Modal,
    ResourceLimitSettings,
    SelectObject,
    Tooltip,
    ValidatedForm,
    ValidatedInput
  }
})
export default class AGTestCommandSettings extends Vue {
  @Prop({required: true, type: AGTestCommand})
  ag_test_command!: AGTestCommand;

  @Prop({required: true, type: AGTestCase})
  ag_test_case!: AGTestCase;

  @Prop({required: true, type: Project})
  project!: Project;

  d_ag_test_command: AGTestCommand | null = null;

  d_editing_test_name = false;
  d_new_test_name = '';
  d_name_form_valid = false;

  d_saving = false;
  d_num_api_errors = 0;
  d_settings_form_is_valid = true;
  d_deleting = false;
  d_show_delete_ag_test_command_modal = false;

  readonly is_not_empty = is_not_empty;
  readonly is_integer = is_integer;
  readonly is_greater_than_or_equal_to_zero = make_min_value_validator(0);
  readonly is_greater_than_or_equal_to_one = make_min_value_validator(1);
  readonly is_less_than_or_equal_to_zero = make_max_value_validator(0);
  readonly string_to_num = string_to_num;
  readonly StdinSource = StdinSource;
  readonly ExpectedOutputSource = ExpectedOutputSource;
  readonly ExpectedReturnCode = ExpectedReturnCode;
  readonly FeedbackConfigLabel = FeedbackConfigLabel;
  readonly FeedbackDescriptions = FeedbackDescriptions;
  readonly format_datetime = format_datetime;

  @Watch('ag_test_command')
  on_test_command_change(new_test_command: AGTestCommand, old_test_command: AGTestCommand) {
    this.d_ag_test_command = deep_copy(new_test_command, AGTestCommand);
  }

  async created() {
    this.d_ag_test_command = deep_copy(this.ag_test_command, AGTestCommand);
  }

  get case_has_exactly_one_command() {
    return this.ag_test_case.ag_test_commands.length === 1;
  }

  @handle_api_errors_async(handle_save_ag_test_case_error)
  save_ag_test_case() {
    let to_save = new AGTestCase(this.ag_test_case);
    to_save.name = this.d_new_test_name;
    return toggle(this, 'd_saving', async () => {
      await to_save.save();
      this.d_editing_test_name = false;
    });
  }

  @handle_api_errors_async(make_error_handler_func('delete_errors'))
  delete_ag_test_command() {
    return toggle(this, 'd_deleting', async () => {
      if (this.case_has_exactly_one_command) {
        await this.ag_test_case!.delete();
      }
      else {
        await this.d_ag_test_command!.delete();
      }
      this.d_show_delete_ag_test_command_modal = false;
    });
  }

  @handle_api_errors_async(handle_save_ag_test_cmd_settings_error)
  save_ag_test_command_settings() {
    return toggle(this, 'd_saving', () => {
      (<APIErrors> this.$refs.api_errors).clear();
      return this.d_ag_test_command!.save();
    });
  }

  toggle_first_failure_feedback() {
    if (this.d_ag_test_command!.first_failed_test_normal_fdbk_config === null) {
      this.d_ag_test_command!.first_failed_test_normal_fdbk_config = {
        visible: true,
        return_code_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        stdout_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        stderr_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        show_points: true,
        show_actual_return_code: true,
        show_actual_stdout: true,
        show_actual_stderr: true,
        show_whether_timed_out: true
      };
    }
    else {
      this.d_ag_test_command!.first_failed_test_normal_fdbk_config = null;
    }
  }

  readonly fdbk_presets = new SafeMap<string, AGTestCommandFeedbackPreset>([
    [
      'Public',
      {
        return_code_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        stdout_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        stderr_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        show_points: true,
        show_actual_return_code: true,
        show_actual_stdout: true,
        show_actual_stderr: true,
        show_whether_timed_out: true
      }
    ],
    [
      'Pass/Fail + Output',
      {
        return_code_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        stdout_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        stderr_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        show_points: true,
        show_actual_return_code: true,
        show_actual_stdout: true,
        show_actual_stderr: true,
        show_whether_timed_out: true
      }
    ],
    [
      'Pass/Fail + Diff',
      {
        return_code_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        stdout_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        stderr_fdbk_level: ValueFeedbackLevel.expected_and_actual,
        show_points: true,
        show_actual_return_code: true,
        show_actual_stdout: false,
        show_actual_stderr: false,
        show_whether_timed_out: true
      }
    ],
    [
      'Pass/Fail + Exit Status',
      {
        return_code_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        stdout_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        stderr_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        show_points: true,
        show_actual_return_code: true,
        show_actual_stdout: false,
        show_actual_stderr: false,
        show_whether_timed_out: true
      }
    ],
    [
      'Pass/Fail',
      {
        return_code_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        stdout_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        stderr_fdbk_level: ValueFeedbackLevel.correct_or_incorrect,
        show_points: true,
        show_actual_return_code: false,
        show_actual_stdout: false,
        show_actual_stderr: false,
        show_whether_timed_out: false
      }
    ],
    [
      'Private',
      {
        return_code_fdbk_level: ValueFeedbackLevel.no_feedback,
        stdout_fdbk_level: ValueFeedbackLevel.no_feedback,
        stderr_fdbk_level: ValueFeedbackLevel.no_feedback,
        show_points: false,
        show_actual_return_code: false,
        show_actual_stdout: false,
        show_actual_stderr: false,
        show_whether_timed_out: false
      }
    ]
  ]);
}

function handle_save_ag_test_case_error(component: AGTestCommandSettings, error: unknown) {
  let api_errors_elt = <APIErrors> component.$refs.ag_test_case_api_errors;
  api_errors_elt.show_errors_from_response(error);
}

function handle_save_ag_test_cmd_settings_error(component: AGTestCommandSettings, error: unknown) {
  let api_errors_elt = <APIErrors> component.$refs.api_errors;
  api_errors_elt.show_errors_from_response(error);
  if (component.d_num_api_errors !== 0) {
    api_errors_elt.$el.scrollIntoView({behavior: 'smooth'});
  }
}
