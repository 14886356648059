import { render, staticRenderFns } from "./validated_input_demo.vue?vue&type=template&id=1da5f2a6&scoped=true&"
import script from "./validated_input_demo.vue?vue&type=script&lang=ts&"
export * from "./validated_input_demo.vue?vue&type=script&lang=ts&"
import style0 from "./validated_input_demo.vue?vue&type=style&index=0&id=1da5f2a6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1da5f2a6",
  null
  
)

export default component.exports