import { render, staticRenderFns } from "./diff.vue?vue&type=template&id=2987c667&scoped=true&"
import script from "./diff.vue?vue&type=script&lang=ts&"
export * from "./diff.vue?vue&type=script&lang=ts&"
import style0 from "./diff.vue?vue&type=style&index=0&id=2987c667&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2987c667",
  null
  
)

export default component.exports