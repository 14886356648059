
  import { Component, Vue } from 'vue-property-decorator';

  import Toggle from '@/components/toggle.vue';
  import ValidatedForm from '@/components/validated_form.vue';
  import ValidatedInput, { ValidatorResponse } from '@/components/validated_input.vue';

  @Component({
    components: { Toggle, ValidatedForm, ValidatedInput }
  })
  export default class ValidatedFormDemo extends Vue {
    d_data = {
      id: 42,
      name: "mars"
    };
    d_form_is_valid = false;

    d_empty_val = '';

    save_data() {
      console.log("saved!");
    }

    is_number(value: string): ValidatorResponse {
      return {
        is_valid: value !== "" && !isNaN(Number(value)),
        error_msg:  "Invalid number!",
      };
    }

    is_not_empty(value: string): ValidatorResponse {
      return {
        is_valid: value !== '',
        error_msg: "This field is required!"
      };
    }

    toggleable_input_value = 42;
    show_toggleable_input = true;
  }
