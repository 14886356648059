
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Course, Group, NewGroupData, Project } from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import GroupMembersForm from '@/components/group_members_form.vue';
import ValidatedForm from '@/components/validated_form.vue';
import { handle_api_errors_async } from '@/error_handling';

export interface GroupMember {
  id: number;
  username: string;
}

@Component({
  components: {
    APIErrors,
    GroupMembersForm,
    ValidatedForm,
  }
})
export default class CreateSingleGroup extends Vue {
  @Prop({required: true, type: Course})
  course!: Course;

  @Prop({required: true, type: Project})
  project!: Project;

  d_creating_group = false;

  @handle_api_errors_async(handle_create_group_error)
  async create_group(usernames: string[]) {
    try {
      this.d_creating_group = true;
      (<APIErrors> this.$refs.api_errors).clear();

      await Group.create(this.project.pk, {member_names: usernames});
    }
    finally {
      this.d_creating_group = false;
    }
  }
}

function handle_create_group_error(component: CreateSingleGroup, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
