
import { Component, Prop, Vue } from 'vue-property-decorator';

import { BuildImageStatus } from 'ag-client-typescript';

@Component
export default class BuildStatusIcon extends Vue {
  @Prop({required: true, type: String})
  status!: BuildImageStatus;

  readonly BuildImageStatus = BuildImageStatus;
}
