var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-container"},[_c('div',{staticClass:"sidebar-menu"},[(_vm.d_ultimate_submission !== null && !_vm.d_collapsed && !_vm.d_loading)?[_c('div',{staticClass:"header"},[_vm._v("Final Graded Submission")]),_c('submission-panel',{class:{
          'active': _vm.d_selected_submission !== null
                    && _vm.d_selected_submission.pk === _vm.d_ultimate_submission.pk
        },attrs:{"submission":_vm.d_ultimate_submission},nativeOn:{"click":function($event){_vm.d_selected_submission = _vm.d_ultimate_submission}}})]:_vm._e(),_c('div',{staticClass:"sidebar-header",class:{'sidebar-header-closed': _vm.d_collapsed}},[_c('span',{staticClass:"collapse-sidebar-button",on:{"click":function($event){_vm.d_collapsed = !_vm.d_collapsed}}},[_c('i',{staticClass:"fas fa-bars"})]),(!_vm.d_collapsed)?_c('span',{staticClass:"header-text"},[_vm._v(" All Submissions ")]):_vm._e()]),(!_vm.d_collapsed)?_c('div',{staticClass:"sidebar-content"},[(_vm.d_loading)?_c('div',{staticClass:"list-loading-container"},[_c('i',{staticClass:"loading-horiz-centered loading-medium fa fa-spinner fa-pulse"})]):[(_vm.d_submissions.length === 0)?_c('div',{staticClass:"no-submissions"},[_vm._v("No submissions")]):_vm._e(),_vm._l((_vm.d_submissions),function(submission,index){return [(index !== 0)?_c('div',{staticClass:"divider"}):_vm._e(),_c('submission-panel',{key:submission.pk,class:{'active': _vm.d_selected_submission !== null
                                            && _vm.d_selected_submission.pk === submission.pk},attrs:{"data-testid":"all_submissions_submission_panel","submission":_vm.d_ultimate_submission !== null
                            && _vm.d_ultimate_submission.pk === submission.pk
                          ? _vm.d_ultimate_submission : submission},nativeOn:{"click":function($event){_vm.d_selected_submission = submission}}})]})]],2):_vm._e()],2),_c('div',{class:['body', {'body-closed': _vm.d_collapsed}]},[(_vm.unrated_hints.length !== 0)?_c('div',{staticStyle:{"margin":".875rem"}},[_c('h3',[_vm._v("Hints")]),_c('div',{staticStyle:{"margin-bottom":".25rem"}},[_vm._v(" We appreciate your feedback. Were these hints useful? ")]),_vm._l((_vm.unrated_hints),function(hint){return _c('div',{key:hint.pk,staticClass:"unlocked-hint-wrapper"},[_c('unlocked-hint',{attrs:{"hint":hint}})],1)})],2):_vm._e(),_vm._m(0),(_vm.d_selected_submission !== null)?_c('div',[_c('submission-detail',{ref:"submission_detail",attrs:{"submission_with_results":_vm.d_selected_submission,"is_ultimate_submission":_vm.is_ultimate_submission,"course":_vm.course,"group":_vm.group}})],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"screen-too-small-msg"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" Your screen may be too small to display this content properly. ")])
}]

export { render, staticRenderFns }