
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Dropdown extends Vue {

  @Prop({required: true, type: Array})
  items!: unknown[];

  @Prop({default: 0, type: Number})
  initial_highlighted_index!: number;

  @Prop({default: "auto", type: String})
  dropdown_height!: string;

  private d_highlighted_index = 0;
  private d_items: unknown[] = [];
  private d_is_open = false;

  created() {
    this.d_items = this.items;
    this.d_highlighted_index = this.initial_highlighted_index;
  }

  mounted() {
    if (this.$slots.header === undefined) {
      throw Error('Missing required slot: "header"');
    }
    let header_slot_content = this.$slots.header[0].elm!;
    header_slot_content.addEventListener("blur", () => {
      this.hide();
    });
    header_slot_content.addEventListener("click", () => {
      this.d_is_open = !this.d_is_open;
    });
  }

  get current_highlighted_index() {
    return this.d_highlighted_index;
  }

  @Watch('items')
  on_items_changed(new_val: unknown[], old_val: unknown[]) {
    this.d_items = new_val;
    if (this.d_highlighted_index >= this.d_items.length && this.d_items.length > 0) {
      this.d_highlighted_index = this.d_items.length - 1;
    }
  }

  get is_open() {
    return this.d_is_open;
  }

  show() {
    this.d_is_open = true;
  }

  hide() {
    this.d_is_open = false;
  }

  choose_item_from_dropdown_menu(item_selected: unknown, index: number) {
    this.d_highlighted_index = index;
    this.$emit("item_selected", item_selected);
    this.hide();
  }

  move_highlighted(event: KeyboardEvent) {
    if (event.code === "Enter" && this.is_open && this.d_items.length > 0) {
      event.preventDefault();
      event.stopPropagation();
      this.choose_item_from_dropdown_menu(
        this.d_items[this.d_highlighted_index], this.d_highlighted_index
      );
    }
    else if (event.code === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();

      this.show();

      if (this.d_highlighted_index < this.d_items.length - 1) {
        this.d_highlighted_index += 1;
      }
    }
    else if (event.code === 'ArrowUp') {
      event.preventDefault();
      event.stopPropagation();

      this.show();

      if (this.d_highlighted_index > 0) {
        this.d_highlighted_index -= 1;
      }
    }
    else if (event.code === 'Escape') {
      this.hide();
    }
  }
}
