
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { MutationTestSuite } from "ag-client-typescript";

import MutationCommand from "@/components/project_admin/mutation_suites/mutation_command.vue";
import Tooltip from "@/components/tooltip.vue";
import {
  deep_copy,
  format_datetime
} from "@/utils";
import { is_not_empty } from '@/validators';

@Component({
  components: {
    MutationCommand,
    Tooltip
  }
})
export default class MutationCommands extends Vue {

  @Prop({required: true, type: MutationTestSuite})
  value!: MutationTestSuite;

  readonly is_not_empty = is_not_empty;
  readonly format_datetime = format_datetime;

  d_mutation_test_suite: MutationTestSuite | null = null;

  @Watch('value', {deep: true})
  on_value_changed(new_val: MutationTestSuite, old_val: MutationTestSuite) {
    this.d_mutation_test_suite = deep_copy(new_val, MutationTestSuite);
  }

  created() {
    this.d_mutation_test_suite = deep_copy(this.value, MutationTestSuite);
  }
}
