
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Course, Group, GroupObserver, Project } from 'ag-client-typescript';

import { ArraySet, member_names_less } from '@/array_set';
import GroupLookup from '@/components/group_lookup.vue';
import GroupMembers from '@/components/project_view/group_members.vue';
import SubmissionList from '@/components/submission_list/submission_list.vue';
import { handle_global_errors_async } from '@/error_handling';
import { Created, Destroyed } from '@/lifecycle';
import { format_datetime, safe_assign } from '@/utils';


@Component({
  components: {
      GroupLookup,
      GroupMembers,
      SubmissionList,
  }
})
export default class StudentLookup extends Vue implements GroupObserver, Created, Destroyed {
  @Prop({required: true, type: Course})
  course!: Course;

  @Prop({required: true, type: Project})
  project!: Project;

  d_groups = new ArraySet<Group>([], {less_func: member_names_less});

  d_selected_group: Group | null = null;

  d_loading = true;

  readonly format_datetime = format_datetime;

  @handle_global_errors_async
  async created() {
    this.d_groups = new ArraySet<Group>(
      await Group.get_all_from_project(this.project.pk), {less_func: member_names_less});

    Group.subscribe(this);
    this.d_loading = false;
  }

  destroyed() {
    Group.unsubscribe(this);
  }

  // Currently the only Group event we care about is when a group
  // is created. This can happen in the ProjectView when the current user
  // completes the group registration process.
  update_group_created(group: Group): void {
    if (group.project === this.project.pk) {
      this.d_groups.insert(group);
    }
  }

  update_group_changed(group: Group): void {
  }

  update_group_merged(new_group: Group, group1_pk: number, group2_pk: number): void {
  }
}
