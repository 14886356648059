
import { Component, Vue } from 'vue-property-decorator';

import DatetimePicker from '@/components/datetime/datetime_picker.vue';
import TimePicker from "@/components/datetime/time_picker.vue";

@Component({
  components: {
    DatetimePicker,
    TimePicker
  }
})
export default class DatetimePickerDemo extends Vue {
  date_1 = "2019-12-25T15:36:37.746189Z";
  date_display_format = { year: 'numeric', month: 'long', day: 'numeric',
                          hour: 'numeric', minute: 'numeric'};
  date_2 = null;

  time = '14:00';
}
