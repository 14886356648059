
import { Component, Vue } from 'vue-property-decorator';

import Dropdown from '@/components/dropdown.vue';
import Toggle from '@/components/toggle.vue';
import ViewFile from '@/components/view_file/view_file.vue';

import ButtonDemo from './button_demo.vue';
import ColorPaletteDemo from './color_palette_demo.vue';
import ContextMenuDemo from './context_menu_demo.vue';
import DatetimePickerDemo from './datetime_picker_demo.vue';
import DiffDemo from './diff_demo.vue';
import DropdownDemo from './dropdown_demo.vue';
import DropdownTypeaheadDemo from './dropdown_typeahead_demo.vue';
import FileUploadDemo from './file_upload_demo.vue';
import ModalDemo from './modal_demo.vue';
import SelectObjectDemo from './select_object_demo.vue';
import ToggleDemo from './toggle_demo.vue';
import TooltipDemo from './tooltip_demo.vue';
import ValidatedFormDemo from './validated_form_demo.vue';
import ValidatedInputDemo from './validated_input_demo.vue';
import ViewFileDemo from './view_file_demo.vue';

@Component({
  components: {
    ButtonDemo,
    ColorPaletteDemo,
    ContextMenuDemo,
    DatetimePickerDemo,
    DiffDemo,
    Dropdown,
    DropdownDemo,
    DropdownTypeaheadDemo,
    FileUploadDemo,
    ModalDemo,
    SelectObjectDemo,
    Toggle,
    ToggleDemo,
    TooltipDemo,
    ValidatedFormDemo,
    ValidatedInputDemo,
    ViewFile,
    ViewFileDemo
  }
})
export default class UIDemos extends Vue {
  d_show_sidebar = false;

  d_current_tab: string = 'buttons';

  mounted() {
    this.d_show_sidebar = window.matchMedia('(min-width: 768px)').matches;
  }
}

