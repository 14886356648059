import { render, staticRenderFns } from "./submission_list.vue?vue&type=template&id=88854db0&scoped=true&"
import script from "./submission_list.vue?vue&type=script&lang=ts&"
export * from "./submission_list.vue?vue&type=script&lang=ts&"
import style0 from "./submission_list.vue?vue&type=style&index=0&id=88854db0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88854db0",
  null
  
)

export default component.exports