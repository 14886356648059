
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import { Annotation } from "ag-client-typescript";

import APIErrors from "@/components/api_errors.vue";
import Modal from "@/components/modal.vue";
import AnnotationForm, { AnnotationFormData } from "@/components/project_admin/handgrading_settings/annotation_form.vue";
import { handle_api_errors_async } from '@/error_handling';
import { deep_copy, format_datetime, safe_assign } from "@/utils";

@Component({
  components: {
    APIErrors,
    AnnotationForm,
    Modal
  }
})
export default class SingleAnnotation extends Vue {
  @Prop({type: Annotation})
  annotation!: Annotation;

  d_annotation: Annotation = new Annotation({
    pk: 0,
    handgrading_rubric: 0,
    last_modified: '',
    short_description: '',
    long_description: '',
    deduction: 0,
    max_deduction: null
  });

  d_deleting = false;
  d_delete_modal_is_open = false;

  d_edit_mode = false;
  d_saving = false;
  d_annotation_form_is_valid = false;

  readonly format_datetime = format_datetime;

  created() {
    this.d_annotation = deep_copy(this.annotation, Annotation);
  }

  @Watch('annotation')
  on_annotation_changed(new_val: Annotation, old_val: Annotation) {
    this.d_annotation = deep_copy(new_val, Annotation);
  }

  @handle_api_errors_async(handle_save_annotation_error)
  async save(form_data: AnnotationFormData) {
    try {
      this.d_saving = true;
      safe_assign(this.d_annotation, form_data);
      await this.d_annotation.save();
      this.d_edit_mode = false;
    }
    finally {
      this.d_saving = false;
    }
  }

  @handle_api_errors_async(handle_delete_annotation_error)
  async delete_annotation() {
    try {
      this.d_deleting = true;
      await this.d_annotation.delete();
      this.d_delete_modal_is_open = false;
    }
    finally {
      this.d_deleting = false;
    }
  }
}

export function handle_save_annotation_error(component: SingleAnnotation, error: unknown) {
  (<APIErrors> component.$refs.save_annotation_errors).show_errors_from_response(error);
}

export function handle_delete_annotation_error(component: SingleAnnotation, error: unknown) {
  (<APIErrors> component.$refs.delete_annotation_errors).show_errors_from_response(error);
}

