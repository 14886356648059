

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tooltip extends Vue {
  @Prop({
    default: "top",
    type: String,
    validator: value => ['top', 'bottom', 'left', 'right'].includes(<string> value)
  })
  placement!: 'top' | 'bottom' | 'left' | 'right';

  @Prop({
    default: "small",
    type: String,
    validator: value => ['small', 'medium', 'large'].includes(<string> value)
  })
  width!: 'small' | 'medium' | 'large';

  mounted() {
    if (this.placement === 'top' || this.placement === 'bottom') {
      let tooltip_text = (<HTMLElement> this.$refs.tooltip_text);
      let right = tooltip_text.getBoundingClientRect().right;

      // Adjust the horizontal positioning if needed to prevent overflow.
      // istanbul ignore next
      if (right > document.body.clientWidth) {
        let correction = right - document.body.clientWidth;
        // !! IMPORTANT !! The "8" in this calculation
        // has to match the $padding SCSS variable defined
        // in this component's <style> tag.
        tooltip_text.style.left = `-${correction + 8}px`;
      }
    }
  }
}
