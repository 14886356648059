
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { MutationTestSuite } from 'ag-client-typescript';

import APIErrors from "@/components/api_errors.vue";
import Toggle from "@/components/toggle.vue";
import ValidatedInput, { ValidatorResponse } from '@/components/validated_input.vue';
import { deep_copy, format_datetime } from "@/utils";
import {
    is_integer,
    is_non_negative,
    is_not_empty,
    is_number,
    make_max_num_digits_validator,
    make_num_decimal_places_validator,
    string_to_num
} from '@/validators';

@Component({
  components: {
    APIErrors,
    Toggle,
    ValidatedInput
  }
})
export default class BuggyImplementations extends Vue {
  @Prop({required: true, type: MutationTestSuite})
  value!: MutationTestSuite;

  readonly is_integer = is_integer;
  readonly is_not_empty = is_not_empty;
  readonly is_number = is_number;
  readonly has_less_than_or_equal_to_four_digits = make_max_num_digits_validator(4);
  readonly has_less_than_or_equal_to_two_decimal_places = make_num_decimal_places_validator(2);
  readonly is_non_negative = is_non_negative;
  readonly string_to_num = string_to_num;
  readonly format_datetime = format_datetime;

  d_buggy_impl_names_text = "";
  d_mutation_test_suite: MutationTestSuite | null = null;
  d_saving = false;
  d_override_max_points = false;

  @Watch('value', {deep: true})
  on_value_changed(new_value: MutationTestSuite, old_value: MutationTestSuite) {
    this.d_mutation_test_suite = deep_copy(new_value, MutationTestSuite);
  }

  created() {
    this.d_mutation_test_suite = deep_copy(this.value, MutationTestSuite);
    this.d_override_max_points = this.d_mutation_test_suite!.max_points !== null;
    this.sort_buggy_impl_names();
  }

  toggle_override_max_points() {
    if (this.d_override_max_points) {
      this.d_mutation_test_suite!.max_points = 0;
    }
    else {
      this.d_mutation_test_suite!.max_points = null;
    }
    this.$emit('input', this.d_mutation_test_suite);
  }

  sort_buggy_impl_names() {
    this.d_mutation_test_suite!.buggy_impl_names.sort(
      (buggy_name_a: string, buggy_name_b: string) =>
        buggy_name_a.localeCompare(buggy_name_b, undefined, {numeric: true}));
  }

  add_buggy_implementation_names() {
    let split_regex = /\s+/g;
    let replace_regex = /,+/g;
    let trimmed_input = this.d_buggy_impl_names_text.trim();
    if (trimmed_input.length === 0) {
      return;
    }
    let split_buggy_impl_names = trimmed_input.replace(replace_regex, " ").split(split_regex);
    split_buggy_impl_names = split_buggy_impl_names.filter((name: string) =>
      (this.d_mutation_test_suite!.buggy_impl_names.findIndex(
        (buggy_name: string) => buggy_name === name) === -1) && name.trim() !== ""
    );
    for (let buggy_name of split_buggy_impl_names) {
      this.d_mutation_test_suite!.buggy_impl_names.push(buggy_name);
    }
    this.d_buggy_impl_names_text = "";
    this.$emit('input', this.d_mutation_test_suite);
    this.sort_buggy_impl_names();
  }
  remove_buggy_implementation_name(index: number) {
    this.d_mutation_test_suite!.buggy_impl_names.splice(index, 1);
    this.$emit('input', this.d_mutation_test_suite);
  }
}
