import { render, staticRenderFns } from "./api_token.vue?vue&type=template&id=725c5600&scoped=true&"
import script from "./api_token.vue?vue&type=script&lang=ts&"
export * from "./api_token.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725c5600",
  null
  
)

export default component.exports