
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import {
  Annotation,
  AnnotationObserver,
  Course,
  Criterion,
  CriterionObserver,
  HandgradingRubric,
  PointsStyle,
  Project,
} from "ag-client-typescript";

import { GlobalData } from '@/app.vue';
import APIErrors from '@/components/api_errors.vue';
import LastSaved from "@/components/last_saved.vue";
import Modal from "@/components/modal.vue";
import AnnotationForm, { AnnotationFormData } from "@/components/project_admin/handgrading_settings/annotation_form.vue";
import { CriterionFormData } from "@/components/project_admin/handgrading_settings/criterion_form.vue";
import SingleAnnotation from "@/components/project_admin/handgrading_settings/single_annotation.vue";
import SingleCriterion from "@/components/project_admin/handgrading_settings/single_criterion.vue";
import SelectObject from '@/components/select_object.vue';
import Toggle from '@/components/toggle.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async, handle_global_errors_async } from '@/error_handling';
import { BeforeDestroy, Created, Mounted } from "@/lifecycle";
import { assert_not_null, deep_copy, format_datetime } from "@/utils";
import {
  is_integer,
  is_not_empty,
  is_number,
  make_max_value_validator,
  make_min_value_validator,
  string_to_num,
} from '@/validators';

import CriterionForm from './criterion_form.vue';

@Component({
  components: {
    AnnotationForm,
    APIErrors,
    CriterionForm,
    Draggable,
    LastSaved,
    Modal,
    SelectObject,
    SingleAnnotation,
    SingleCriterion,
    Toggle,
    Tooltip,
    ValidatedForm,
    ValidatedInput,
  }
})
export default class HandgradingSettings extends Vue implements Created,
                                                                BeforeDestroy,
                                                                AnnotationObserver,
                                                                CriterionObserver {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  @Prop({required: true, type: Project})
  project!: Project;

  d_handgrading_rubric: HandgradingRubric | null = null;

  d_courses_is_admin_for: Course[] | null = null;
  d_course_to_import_from: Course | null = null;
  // When we select a course to import from, we'll load
  // that course's projects and store them here.
  d_selected_course_projects: Project[] | null = null;
  d_loading_projects: boolean = false;
  d_project_to_import_from: Project | null = null;
  d_new_rubric_request_pending = false;
  d_import_request_pending = false;

  d_override_max_points = false;

  d_loading = true;
  private d_mounted = false;
  d_saving = false;
  d_settings_form_is_valid = false;
  d_creating_criterion = false;
  d_create_criterion_form_is_valid = false;
  d_create_criterion_modal_is_open = false;

  d_creating_annotation = false;
  d_create_annotation_form_is_valid = false;
  d_create_annotation_modal_is_open = false;

  readonly is_not_empty = is_not_empty;
  readonly is_number = is_number;
  readonly is_integer = is_integer;
  readonly string_to_num = string_to_num;
  readonly is_positive = make_min_value_validator(1);
  readonly is_non_positive = make_max_value_validator(0);
  readonly is_positive_or_empty = make_min_value_validator(1, true);

  get max_points_validators() {
    return [(value: string) => {
      assert_not_null(this.d_handgrading_rubric);

      let validators = [this.is_positive_or_empty];
      if (this.d_handgrading_rubric!.points_style === PointsStyle.start_at_max_and_subtract) {
        validators = [is_not_empty, is_integer, this.is_positive];
      }

      for (let validator of validators) {
        let result = validator(value);
        if (!result.is_valid) {
          return result;
        }
      }

      return {is_valid: true, error_msg: ''};
    }];
  }

  readonly format_datetime = format_datetime;

  readonly PointsStyle = PointsStyle;

  @handle_global_errors_async
  async created() {
    Criterion.subscribe(this);
    Annotation.subscribe(this);

    if (this.project.has_handgrading_rubric) {
      this.d_handgrading_rubric = await HandgradingRubric.get_from_project(this.project.pk);
    }
    else {
      this.d_courses_is_admin_for = await this.d_globals.current_user!.courses_is_admin_for();
      let current_course = this.d_courses_is_admin_for.find(
        course => course.pk === this.project.course);
      assert_not_null(current_course);
      this.d_course_to_import_from = current_course!;
      await this.load_projects_to_import_from(this.d_course_to_import_from);
    }

    this.d_loading = false;
  }

  beforeDestroy() {
    Annotation.unsubscribe(this);
    Criterion.unsubscribe(this);
  }

  @Watch('d_handgrading_rubric.points_style')
  on_points_style_changed() {
    let max_points_input = this.$refs.max_points;
    if (max_points_input !== undefined) {
      (<ValidatedInput> max_points_input).rerun_validators();
    }
  }

  @handle_global_errors_async
  async create_new_rubric() {
    try {
      this.d_new_rubric_request_pending = true;
      this.d_handgrading_rubric = await HandgradingRubric.create(this.project.pk, {});
    }
    finally {
      this.d_new_rubric_request_pending = false;
    }
  }

  @handle_global_errors_async
  async load_projects_to_import_from(course: Course) {
    this.d_project_to_import_from = null;
    try {
      this.d_loading_projects = true;
      let projects = await Project.get_all_from_course(course.pk);
      this.d_selected_course_projects = projects.filter(
        (project) => project.has_handgrading_rubric);
    }
    finally {
      this.d_loading_projects = false;
    }
  }

  @handle_global_errors_async
  async import_rubric() {
    assert_not_null(this.d_project_to_import_from);
    try {
      this.d_import_request_pending = true;
      this.d_handgrading_rubric = await HandgradingRubric.import_from_project(
        this.project.pk, this.d_project_to_import_from.pk);
    }
    finally {
      this.d_import_request_pending = false;
    }
  }

  @handle_api_errors_async(handle_save_rubric_settings_error)
  async save_rubric_settings() {
    try {
      this.d_saving = true;
      (<APIErrors> this.$refs.settings_form_errors).clear();

      assert_not_null(this.d_handgrading_rubric);
      await this.d_handgrading_rubric.save();
    }
    finally {
      this.d_saving = false;
    }
  }

  // ----------------------------------------------------------------------------------------------

  @handle_api_errors_async(handle_create_criterion_error)
  async create_criterion(form_data: CriterionFormData) {
    try {
      this.d_creating_criterion = true;
      (<APIErrors> this.$refs.create_criterion_errors).clear();

      assert_not_null(this.d_handgrading_rubric);
      await Criterion.create(this.d_handgrading_rubric!.pk, form_data);

      this.d_create_criterion_modal_is_open = false;
    }
    finally {
      this.d_creating_criterion = false;
    }
  }

  @handle_global_errors_async
  set_criteria_order() {
    return Criterion.update_order(
      this.d_handgrading_rubric!.pk,
      this.d_handgrading_rubric!.criteria.map(criterion => criterion.pk)
    );
  }

  update_criteria_order_changed(criterion_list: number[], handgrading_rubric_pk: number): void {
  }

  update_criterion_changed(criterion: Criterion): void {
    if (this.d_handgrading_rubric !== null
        && criterion.handgrading_rubric === this.d_handgrading_rubric.pk) {
      let index = this.d_handgrading_rubric.criteria.findIndex((item) => item.pk === criterion.pk);
      if (index !== -1) {
        Vue.set(this.d_handgrading_rubric.criteria, index, deep_copy(criterion, Criterion));
      }
    }
  }

  update_criterion_created(criterion: Criterion): void {
    if (this.d_handgrading_rubric !== null
        && criterion.handgrading_rubric === this.d_handgrading_rubric.pk) {
      this.d_handgrading_rubric.criteria.push(criterion);
    }
  }

  update_criterion_deleted(criterion: Criterion): void {
    if (this.d_handgrading_rubric !== null
        && criterion.handgrading_rubric === this.d_handgrading_rubric.pk) {
      let index = this.d_handgrading_rubric.criteria.findIndex((item) => item.pk === criterion.pk);
      if (index !== -1) {
        this.d_handgrading_rubric.criteria.splice(index, 1);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------

  @handle_api_errors_async(handle_create_annotation_error)
  async create_annotation(form_data: AnnotationFormData) {
    try {
      this.d_creating_annotation = true;
      (<APIErrors> this.$refs.create_annotation_errors).clear();

      assert_not_null(this.d_handgrading_rubric);
      await Annotation.create(this.d_handgrading_rubric!.pk, form_data);

      this.d_create_annotation_modal_is_open = false;
    }
    finally {
      this.d_creating_annotation = false;
    }
  }

  @handle_global_errors_async
  set_annotations_order() {
    return Annotation.update_order(
      this.d_handgrading_rubric!.pk,
      this.d_handgrading_rubric!.annotations.map(annotation => annotation.pk)
    );
  }

  update_annotations_order_changed(annotation_list: number[],
                                   handgrading_rubric_pk: number): void {
  }

  update_annotation_changed(annotation: Annotation): void {
    if (this.d_handgrading_rubric !== null
        && annotation.handgrading_rubric === this.d_handgrading_rubric.pk) {
      let index = this.d_handgrading_rubric.annotations.findIndex(
        (item) => item.pk === annotation.pk);
      if (index !== -1) {
        Vue.set(this.d_handgrading_rubric.annotations, index, deep_copy(annotation, Annotation));
      }
    }
  }

  update_annotation_created(annotation: Annotation): void {
    if (this.d_handgrading_rubric !== null
        && annotation.handgrading_rubric === this.d_handgrading_rubric.pk) {
      this.d_handgrading_rubric.annotations.push(annotation);
    }
  }

  update_annotation_deleted(annotation: Annotation): void {
    if (this.d_handgrading_rubric !== null
        && annotation.handgrading_rubric === this.d_handgrading_rubric.pk) {
      let index = this.d_handgrading_rubric.annotations.findIndex(
        (item) => item.pk === annotation.pk);
      if (index !== -1) {
        this.d_handgrading_rubric.annotations.splice(index, 1);
      }
    }
  }
}

export function handle_save_rubric_settings_error(component: HandgradingSettings, error: unknown) {
  (<APIErrors> component.$refs.settings_form_errors).show_errors_from_response(error);
}

export function handle_create_criterion_error(component: HandgradingSettings, error: unknown) {
  (<APIErrors> component.$refs.create_criterion_errors).show_errors_from_response(error);
}

export function handle_create_annotation_error(component: HandgradingSettings, error: unknown) {
  (<APIErrors> component.$refs.create_annotation_errors).show_errors_from_response(error);
}

