
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { User } from 'ag-client-typescript';

import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput, { ValidatorResponse } from '@/components/validated_input.vue';
import { is_email } from '@/utils';

@Component({
  components: {
    Tooltip,
    ValidatedForm,
    ValidatedInput
  }
})
export default class Roster extends Vue {
  @Prop({required: true, type: String})
  role!: string;

  @Prop({required: true, validator: prop => prop instanceof Array || prop === null})
  roster!: User[];

  @Prop({default: false, type: Boolean})
  include_replace_button!: boolean;

  d_form_is_valid = false;

  d_form_text = '';
  d_roster: User[] | null = null;

  async created() {
    this.initialize_roster(this.roster);
  }

  @Watch('roster')
  on_roster_change(new_users: User[] | null, old_users: User[] | null) {
    this.initialize_roster(new_users);
  }

  initialize_roster(users: User[] | null) {
    if (users === null) {
      return;
    }

    this.d_roster = users.slice(0);
    this.d_roster.sort((user_a: User, user_b: User) => {
      if (user_a.username <= user_b.username) {
        return -1;
      }
      return 1;
    });
  }

  email_list_validator(value: string): ValidatorResponse {
    let {valid_emails, invalid_emails} = parse_emails(value);

    let is_valid = invalid_emails.length === 0;
    let error_msg = is_valid ? '' : invalid_emails[0] + " is not a valid email.";
    return {
      is_valid: is_valid,
      error_msg: error_msg,
    };
  }

  add_users() {
    let {valid_emails} = parse_emails(this.d_form_text);
    this.$emit('add_users', valid_emails);
  }

  replace_users() {
    let {valid_emails} = parse_emails(this.d_form_text);
    this.$emit('replace_users', valid_emails);
  }

  remove_person_from_roster(person_to_delete: User[], index: number) {
    this.$emit('remove_user', person_to_delete);
  }

  reset_form() {
    this.d_form_text = "";
    let validated_input = <ValidatedInput> this.$refs.add_users_textarea;
    validated_input.reset_warning_state();
    this.d_form_is_valid = false;
  }
}

function parse_emails(to_parse: string): {valid_emails: string[], invalid_emails: string[]} {
  let valid_emails = [];
  let invalid_emails = [];

  // Replace commas with spaces
  to_parse = to_parse.replace(/,+/g, " ");
  let trimmed_input = to_parse.trim();
  // Split at whitespace
  let usernames = trimmed_input.split(/\s+/g);
  for (let username of usernames) {
    if (is_email(username)) {
      valid_emails.push(username);
    }
    else {
      invalid_emails.push(username);
    }
  }

  return {valid_emails: valid_emails, invalid_emails: invalid_emails};
}
