
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { AGTestCaseFeedbackConfig } from 'ag-client-typescript';

import FeedbackConfigPanel from '../feedback_config_panel/feedback_config_panel.vue';

@Component({
  components: {FeedbackConfigPanel}
})
export default class AGTestCaseFdbkConfigPanel extends Vue {
  // @Prop({required: true, type: String})
  // config_name!: string;

  @Prop({required: true, type: Object})
  value!: AGTestCaseFeedbackConfig;

  d_feedback_config: AGTestCaseFeedbackConfig | null = null;

  @Watch('value')
  on_value_changed(new_value: AGTestCaseFeedbackConfig, old_value: AGTestCaseFeedbackConfig) {
    this.d_feedback_config = JSON.parse(JSON.stringify(new_value));
  }

  created() {
    this.d_feedback_config = JSON.parse(JSON.stringify(this.value));
  }
}
