
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { AGCommand } from 'ag-client-typescript';

import ResourceLimitSettings from '@/components/project_admin/resource_limit_settings.vue';
import ValidatedInput, { ValidatorResponse } from "@/components/validated_input.vue";
import {
    is_integer,
    is_non_negative,
    is_not_empty,
    make_min_value_validator,
    string_to_num
} from '@/validators';

@Component({
  components: {
    ResourceLimitSettings,
    ValidatedInput
  }
})
export default class MutationCommand extends Vue {

  @Prop({required: true, type: Object})
  value!: AGCommand;

  @Prop({default: false, type: Boolean})
  include_command_name_input!: boolean;

  d_is_open = false;
  d_ag_command: AGCommand | null = null;

  readonly is_not_empty = is_not_empty;
  readonly is_integer = is_integer;
  readonly is_non_negative = is_non_negative;
  readonly is_greater_than_or_equal_to_one = make_min_value_validator(1);
  readonly string_to_num = string_to_num;

  toggle_is_open() {
    this.d_is_open = !this.d_is_open;
  }

  @Watch('value')
  on_value_changed(new_value: AGCommand, old_value: AGCommand) {
    this.d_ag_command = JSON.parse(JSON.stringify(new_value));
  }

  created() {
    this.d_ag_command = JSON.parse(JSON.stringify(this.value));
  }
}
