import { render, staticRenderFns } from "./handgrading.vue?vue&type=template&id=46502c52&scoped=true&"
import script from "./handgrading.vue?vue&type=script&lang=ts&"
export * from "./handgrading.vue?vue&type=script&lang=ts&"
import style0 from "./handgrading.vue?vue&type=style&index=0&id=46502c52&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46502c52",
  null
  
)

export default component.exports