
import { Component, Prop, Vue } from 'vue-property-decorator';

import { format_datetime, format_datetime_short } from '@/utils';

@Component
export default class LastSaved extends Vue {
  @Prop({required: true, type: String})
  last_modified!: string;

  @Prop({default: false, type: Boolean})
  saving!: boolean;

  @Prop({default: true, type: Boolean})
  short!: boolean;

  readonly format_datetime = format_datetime;
  readonly format_datetime_short = format_datetime_short;
}
