
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Course, Semester } from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import Modal from '@/components/modal.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async } from '@/error_handling';
import { format_course_name } from '@/utils';
import { is_not_empty, is_number, make_min_value_validator } from '@/validators';

@Component({
  components: {
    APIErrors,
    Modal,
    ValidatedForm,
    ValidatedInput
  }
})
export default class SingleCourse extends Vue {

  @Prop({required: true, type: Course})
  course!: Course;

  @Prop({default: false, type: Boolean})
  is_admin!: boolean;

  new_course_name = "";
  new_course_semester: Semester = Semester.fall;
  new_course_year: number = 2000;

  semesters = [Semester.fall, Semester.winter, Semester.spring, Semester.summer];
  clone_course_form_is_valid = false;

  d_cloning = false;
  d_show_clone_course_modal = false;

  readonly is_not_empty = is_not_empty;
  readonly is_number = is_number;
  readonly is_valid_course_year = make_min_value_validator(2000);
  readonly format_course_name = format_course_name;

  created() {
    this.new_course_name = this.course.name;
    if (this.course.semester !== null) {
      this.new_course_semester = this.course.semester;
    }
    let current_year = (new Date()).getFullYear();
    this.new_course_year = this.course.year !== null ? this.course.year : current_year;
  }

  @handle_api_errors_async(handle_add_copied_course_error)
  async make_copy_of_course() {
    try {
      this.d_cloning = true;
      await this.course.copy(
        this.new_course_name, this.new_course_semester, this.new_course_year
      );
      this.d_show_clone_course_modal = false;
    }
    finally {
      this.d_cloning = false;
    }
  }
}

function handle_add_copied_course_error(component: SingleCourse, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}

