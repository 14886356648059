
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Group, Project } from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import GroupLookup from '@/components/group_lookup.vue';
import ValidatedForm from '@/components/validated_form.vue';
import { handle_api_errors_async } from '@/error_handling';

@Component({
  components: {
    APIErrors,
    GroupLookup,
    ValidatedForm
  }
})
export default class MergeGroups extends Vue {
  @Prop({required: true, type: Project})
  project!: Project;

  @Prop({required: true, type: Array})
  groups!: Group[];

  group_1: Group | null = null;
  group_2: Group | null = null;
  d_merging = false;

  get available_groups() {
    if (this.group_1 === null && this.group_2 === null) {
      return this.groups;
    }
    else if (this.group_1 === null) {
      return this.groups.filter(group => group.pk !== this.group_2!.pk);
    }
    else if (this.group_2 === null) {
      return this.groups.filter(group => group.pk !== this.group_1!.pk);
    }
    return this.groups.filter(group => group.pk !== this.group_1!.pk
                                       && group.pk !== this.group_2!.pk);
  }

  @handle_api_errors_async(handle_merge_groups_error)
  async merge_groups() {
    try {
      this.d_merging = true;
      await this.group_1!.merge_groups(this.group_2!.pk);
    }
    finally {
      this.d_merging = false;
    }
  }
}

function handle_merge_groups_error(component: MergeGroups, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
