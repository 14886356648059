
import { Component, Inject, Vue } from 'vue-property-decorator';

import {
  Course,
  Group,
  GroupObserver,
  HandgradingResult,
  HandgradingRubric,
  HttpError,
  Project
} from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import GroupRegistration from '@/components/project_view/group_registration/group_registration.vue';
import Handgrading from '@/components/project_view/handgrading/handgrading.vue';
import HandgradingContainer from '@/components/project_view/handgrading/handgrading_container.vue';
import StudentLookup from '@/components/project_view/student_lookup.vue';
import Submit from '@/components/project_view/submit.vue';
import SubmissionList from '@/components/submission_list/submission_list.vue';
import { handle_global_errors_async } from '@/error_handling';
import { assert_not_null, format_datetime, get_query_param } from '@/utils';

@Component({
  components: {
    GroupRegistration,
    Handgrading,
    HandgradingContainer,
    StudentLookup,
    SubmissionList,
    Submit,
  }
})
export default class ProjectView extends Vue implements GroupObserver {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  d_current_tab: string = 'submit';
  d_loading = true;

  d_load_my_submissions = false;

  project: Project | null = null;
  course: Course | null = null;
  group: Group | null = null;
  handgrading_rubric: HandgradingRubric | null = null;
  handgrading_result: HandgradingResult | null = null;

  // The identifiers for tabs that have been clicked on and therefore
  // have had there content loaded.
  d_loaded_tabs: Set<string> = new Set();

  readonly format_datetime = format_datetime;

  @handle_global_errors_async
  async mounted() {
    this.project = await Project.get_by_pk(Number(this.$route.params.project_id));
    this.course = await Course.get_by_pk(this.project.course);

    let groups_is_member_of = await this.d_globals.current_user!.groups_is_member_of();
    let result = groups_is_member_of.find(group => group.project === this.project!.pk);
    if (result !== undefined) {
      this.group = result;
    }

    await this.d_globals.set_current_project(this.project, this.course);
    await Promise.all([this.load_handgrading(), this.load_handgrading_result()]);
    this.initialize_current_tab();

    Group.subscribe(this);
    this.d_loading = false;
  }

  beforeDestroy() {
    Group.unsubscribe(this);
  }

  private initialize_current_tab() {
    let requested_tab = get_query_param(this.$route.query, "current_tab");
    if (requested_tab !== null) {
      this.set_current_tab(requested_tab);
    }
    else {
      if (this.d_globals.user_roles.is_handgrader) {
        this.d_current_tab = 'handgrading';
      }
      this.mark_tab_as_loaded(this.d_current_tab);
    }
  }

  private set_current_tab(tab_id: string) {
    if (tab_id === 'my_submissions' && this.group === null
        || tab_id === 'handgrading' && this.handgrading_rubric === null
        || tab_id === 'handgrading_result' && this.handgrading_result === null) {
      return;
    }

    this.d_current_tab = tab_id;
    this.$router.replace({
      query: {...this.$route.query, current_tab: tab_id}
    }).catch(err => {});  // Ignore "NavigationDuplicated"
    this.mark_tab_as_loaded(tab_id);
  }

  private mark_tab_as_loaded(tab_id: string) {
    if (!this.d_loaded_tabs.has(tab_id)) {
      let new_loaded_tabs = new Set(this.d_loaded_tabs);
      new_loaded_tabs.add(tab_id);
      this.d_loaded_tabs = new_loaded_tabs;
    }
  }

  private async load_handgrading() {
    assert_not_null(this.project);
    if (!this.project!.has_handgrading_rubric) {
      return;
    }

    if (this.d_globals.user_roles.is_staff || this.d_globals.user_roles.is_handgrader) {
      this.handgrading_rubric = await HandgradingRubric.get_from_project(this.project!.pk);
    }
  }

  private async load_handgrading_result() {
    if (this.group === null) {
      return;
    }

    try {
      this.handgrading_result = await HandgradingResult.get_by_group_pk(this.group.pk);
    }
    catch (e) {
      if (!(e instanceof HttpError) || (e.status !== 403 && e.status !== 404)) {
        // istanbul ignore next
        throw e;
      }
    }
  }

  // Whether to show the "submit" and "my submissions" tabs
  get can_submit() {
    return !this.d_globals.user_roles.is_handgrader
           || this.d_globals.user_roles.is_student
           || this.d_globals.user_roles.is_staff;
  }

  @handle_global_errors_async
  async on_submit() {
    this.set_current_tab('my_submissions');
    return this.group!.refresh();
  }

  update_group_changed(group: Group): void {}

  update_group_created(group: Group): void {
    let has_current_user = group.member_names.find(
      member => member === this.d_globals.current_user!.username) !== undefined;
    if (this.project !== null && group.project === this.project.pk && has_current_user) {
      this.group = group;
    }
  }

  update_group_merged(new_group: Group, group1_pk: number, group2_pk: number): void {}
}
