
import { Component, Prop, Vue } from 'vue-property-decorator';

import * as ag_cli from 'ag-client-typescript';

import { ArraySet, member_names_less, pk_less, pk_more } from '@/array_set';
import APIErrors from "@/components/api_errors.vue";
import Collapsible from '@/components/collapsible.vue';
import GroupLookup from '@/components/group_lookup.vue';
import Tooltip from '@/components/tooltip.vue';
import { handle_api_errors_async, handle_global_errors_async } from '@/error_handling';
import { BeforeDestroy, Created } from '@/lifecycle';
import { Poller } from '@/poller';
import { SafeMap } from '@/safe_map';
import { deep_copy, format_datetime, safe_assign, toggle } from '@/utils';

import {
  find_parent_suite,
  find_parent_suite_and_test_case,
  sort_by_ordering,
  update_changed_ag_test_case,
} from '../suite_observer_utils';

import RerunTaskDetail from './rerun_task_detail.vue';
import SubmissionSelector from './submission_selector.vue';

interface GroupWithSubmissions {
  group: ag_cli.Group;
  submissions: ag_cli.Submission[];
}

@Component({
  components: {
    APIErrors,
    Collapsible,
    GroupLookup,
    RerunTaskDetail,
    SubmissionSelector,
    Tooltip,
  }
})
export default class RerunSubmissions extends Vue implements ag_cli.GroupObserver,
                                                             ag_cli.AGTestSuiteObserver,
                                                             ag_cli.AGTestCaseObserver,
                                                             ag_cli.MutationTestSuiteObserver,
                                                             Created,
                                                             BeforeDestroy {
  @Prop({required: true, type: ag_cli.Project})
  project!: ag_cli.Project;

  d_rerun_tasks: ag_cli.RerunSubmissionTask[] = [];

  d_rerun_all_submissions = false;
  task_poller: Poller | null = null;

  d_groups: ag_cli.Group[] = [];
  d_selected_groups = new ArraySet([], {less_func: member_names_less});
  d_selected_submissions = new ArraySet<ag_cli.Submission>([], {less_func: pk_less});

  d_rerun_all_ag_test_cases = true;
  d_ag_test_suites: ag_cli.AGTestSuite[] = [];
  d_selected_test_cases_by_suite_pk = new SafeMap<number, Set<number>>();

  d_rerun_all_mutation_test_suites = true;
  d_mutation_test_suites: ag_cli.MutationTestSuite[] = [];
  d_selected_mutation_test_suite_pks = new Set<number>();

  d_starting_rerun = false;
  d_loading = true;

  @handle_global_errors_async
  async created() {
    await this.load_rerun_tasks();
    this.d_groups = await ag_cli.Group.get_all_from_project(this.project.pk);
    this.d_ag_test_suites = await ag_cli.AGTestSuite.get_all_from_project(this.project.pk);
    this.d_mutation_test_suites = await ag_cli.MutationTestSuite.get_all_from_project(
      this.project.pk);

    ag_cli.Group.subscribe(this);
    ag_cli.AGTestSuite.subscribe(this);
    ag_cli.AGTestCase.subscribe(this);
    ag_cli.MutationTestSuite.subscribe(this);

    this.task_poller = new Poller(() => this.load_rerun_tasks(), 30);
    // tslint:disable-next-line no-floating-promises
    this.task_poller.start_after_delay();

    this.d_loading = false;
  }

  beforeDestroy() {
    ag_cli.Group.unsubscribe(this);
    ag_cli.AGTestSuite.unsubscribe(this);
    ag_cli.AGTestCase.unsubscribe(this);
    ag_cli.MutationTestSuite.unsubscribe(this);

    if (this.task_poller !== null) {
      this.task_poller.stop();
    }
  }

  async load_rerun_tasks() {
    this.d_rerun_tasks = await ag_cli.RerunSubmissionTask.get_all_from_project(this.project.pk);
  }

  @handle_api_errors_async(handle_start_rerun_error)
  start_rerun() {
    return toggle(this, 'd_starting_rerun', async () => {
        let rerun = await ag_cli.RerunSubmissionTask.create(this.project.pk, {
            rerun_all_submissions: this.d_rerun_all_submissions,
            submission_pks: this.selected_submission_pks,

            rerun_all_ag_test_suites: this.d_rerun_all_ag_test_cases,
            ag_test_suite_data: this.get_ag_test_suite_data_for_request(),

            rerun_all_mutation_test_suites: this.d_rerun_all_mutation_test_suites,
            mutation_suite_pks: [...this.d_selected_mutation_test_suite_pks.values()]
        });

        this.d_rerun_tasks.unshift(rerun);
    });
  }

  get_ag_test_suite_data_for_request() {
    let result: {[key: number]: number[]} = {};
    for (let [suite_pk, test_case_pks] of this.d_selected_test_cases_by_suite_pk) {
      result[suite_pk] = [...test_case_pks.values()];
    }
    return result;
  }

  get selected_submission_pks() {
    return this.d_selected_submissions.data.map(submission => submission.pk);
  }

  select_submissions(submissions: ag_cli.Submission[]) {
    for (let submission of submissions) {
      this.d_selected_submissions.insert(submission);
    }
  }

  unselect_submissions(submissions: ag_cli.Submission[]) {
    for (let submission of submissions) {
      this.d_selected_submissions.remove(submission);
    }
  }

  unselect_group(group: ag_cli.Group) {
    this.d_selected_groups.remove(group);
    this.d_selected_submissions = new ArraySet(
      this.d_selected_submissions.data.filter(submission => submission.group !== group.pk),
      {less_func: pk_less}
    );
  }

  toggle_ag_test_suite_selected(ag_test_suite: ag_cli.AGTestSuite) {
    let copy = new SafeMap(this.d_selected_test_cases_by_suite_pk);

    if (copy.has(ag_test_suite.pk)) {
      copy.delete(ag_test_suite.pk);
    }
    else {
      copy.set(ag_test_suite.pk, new Set());
    }

    this.d_selected_test_cases_by_suite_pk = copy;
  }

  toggle_ag_test_case_selected(ag_test_case: ag_cli.AGTestCase) {
    let copy = new SafeMap(this.d_selected_test_cases_by_suite_pk);

    let ag_test_case_pks = copy.get(
      ag_test_case.ag_test_suite, new Set(), true
    );
    if (ag_test_case_pks.has(ag_test_case.pk)) {
      ag_test_case_pks.delete(ag_test_case.pk);
    }
    else {
      ag_test_case_pks.add(ag_test_case.pk);
    }

    this.d_selected_test_cases_by_suite_pk = copy;
  }

  ag_test_case_is_checked(ag_test_case: ag_cli.AGTestCase) {
    let ag_test_case_pks = this.d_selected_test_cases_by_suite_pk.get(
      ag_test_case.ag_test_suite, new Set());
    return ag_test_case_pks.has(ag_test_case.pk);
  }

  toggle_mutation_test_suite_selected(mutation_test_suite: ag_cli.MutationTestSuite) {
    let copy = new Set(this.d_selected_mutation_test_suite_pks);

    if (copy.has(mutation_test_suite.pk)) {
      copy.delete(mutation_test_suite.pk);
    }
    else {
      copy.add(mutation_test_suite.pk);
    }

    this.d_selected_mutation_test_suite_pks = copy;
  }

  get num_submissions_to_rerun() {
    if (this.d_rerun_all_submissions) {
      return 'ALL';
    }

    return this.selected_submission_pks.length;
  }

  get num_tests_to_rerun() {
    if (this.d_rerun_all_ag_test_cases) {
      return this.d_ag_test_suites.reduce((accum, suite) => accum + suite.ag_test_cases.length, 0);
    }

    let count = 0;
    for (let [suite_pk, test_case_pks] of this.d_selected_test_cases_by_suite_pk) {
      if (test_case_pks.size !== 0) {
        count += test_case_pks.size;
      }
      else {
        count += this.d_ag_test_suites.find(item => item.pk === suite_pk)!.ag_test_cases.length;
      }
    }

    return count;
  }

  get num_ag_test_suites_to_rerun() {
    if (this.d_rerun_all_ag_test_cases) {
      return this.d_ag_test_suites.length;
    }
    return this.d_selected_test_cases_by_suite_pk.size;
  }

  get num_mutation_test_suites_to_rerun() {
    if (this.d_rerun_all_mutation_test_suites) {
      return this.d_mutation_test_suites.length;
    }

    return this.d_selected_mutation_test_suite_pks.size;
  }

  // --------------------------------------------------------------------------

  update_group_created(group: ag_cli.Group): void {
    if (group.project === this.project.pk) {
      this.d_groups.push(deep_copy(group, ag_cli.Group));
      this.sort_groups();
    }
  }

  update_group_changed(group: ag_cli.Group): void {
    if (group.project === this.project.pk) {
      let index = this.d_groups.findIndex(g => g.pk === group.pk);
      Vue.set(this.d_groups, index, deep_copy(group, ag_cli.Group));
      this.sort_groups();
    }
  }

  update_group_merged(new_group: ag_cli.Group, group1_pk: number, group2_pk: number): void {
    if (new_group.project !== this.project.pk) {
      // istanbul ignore next
      return;
    }
    this.d_groups.splice(this.d_groups.findIndex(group => group.pk === group1_pk), 1);
    this.d_groups.splice(this.d_groups.findIndex(group => group.pk === group2_pk), 1);
    this.d_groups.push(deep_copy(new_group, ag_cli.Group));

    this.sort_groups();
  }

  private sort_groups() {
    this.d_groups.sort(
      (first, second) => first.member_names[0].localeCompare(second.member_names[0]));
  }

  update_ag_test_suite_created(ag_test_suite: ag_cli.AGTestSuite): void {
    if (ag_test_suite.project === this.project.pk) {
      this.d_ag_test_suites.push(deep_copy(ag_test_suite, ag_cli.AGTestSuite));
    }
  }

  update_ag_test_suite_changed(ag_test_suite: ag_cli.AGTestSuite): void {
    if (ag_test_suite.project === this.project.pk) {
      let index = this.d_ag_test_suites.findIndex(suite => suite.pk === ag_test_suite.pk);
      Vue.set(this.d_ag_test_suites, index, deep_copy(ag_test_suite, ag_cli.AGTestSuite));
    }
  }

  update_ag_test_suite_deleted(ag_test_suite: ag_cli.AGTestSuite): void {
    if (ag_test_suite.project === this.project.pk) {
      let index = this.d_ag_test_suites.findIndex(suite => ag_test_suite.pk === suite.pk);
      this.d_ag_test_suites.splice(index, 1);

      this.d_selected_test_cases_by_suite_pk.delete(ag_test_suite.pk);
    }
  }

  update_ag_test_suites_order_changed(project_pk: number, ag_test_suite_order: number[]): void {
    if (this.project.pk === project_pk) {
      sort_by_ordering(this.d_ag_test_suites, ag_test_suite_order);
    }
  }

  update_ag_test_case_created(ag_test_case: ag_cli.AGTestCase): void {
    let parent_suite = find_parent_suite(ag_test_case, this.d_ag_test_suites);
    if (parent_suite !== null) {
      parent_suite.ag_test_cases.push(deep_copy(ag_test_case, ag_cli.AGTestCase));
    }
  }

  update_ag_test_case_changed(ag_test_case: ag_cli.AGTestCase): void {
    update_changed_ag_test_case(deep_copy(ag_test_case, ag_cli.AGTestCase), this.d_ag_test_suites);
  }

  update_ag_test_case_deleted(ag_test_case: ag_cli.AGTestCase): void {
    let parent_suite_index = this.d_ag_test_suites.findIndex(
      (ag_suite) => ag_suite.pk === ag_test_case.ag_test_suite
    );
    if (parent_suite_index === -1) {
      // Test case could belong to another project
      // istanbul ignore next
      return;
    }

    let parent_suite = this.d_ag_test_suites[parent_suite_index];
    let case_index = parent_suite!.ag_test_cases.findIndex(
      (test_case) => test_case.pk === ag_test_case.pk
    );

    parent_suite!.ag_test_cases.splice(case_index, 1);
  }

  update_ag_test_cases_order_changed(ag_test_suite_pk: number, ag_test_case_order: number[]): void {
    let ag_test_suite = this.d_ag_test_suites.find(suite => suite.pk === ag_test_suite_pk);
    if (ag_test_suite !== undefined) {
      sort_by_ordering(ag_test_suite.ag_test_cases, ag_test_case_order);
    }
  }

  update_mutation_test_suite_created(mutation_test_suite: ag_cli.MutationTestSuite): void {
    if (mutation_test_suite.project === this.project.pk) {
      this.d_mutation_test_suites.push(mutation_test_suite);
    }
  }

  update_mutation_test_suite_changed(mutation_test_suite: ag_cli.MutationTestSuite): void {
    if (mutation_test_suite.project === this.project.pk) {
      let index = this.d_mutation_test_suites.findIndex(
        suite => suite.pk === mutation_test_suite.pk);
      Vue.set(
        this.d_mutation_test_suites,
        index,
        deep_copy(mutation_test_suite, ag_cli.MutationTestSuite)
      );
    }
  }

  update_mutation_test_suite_deleted(mutation_test_suite: ag_cli.MutationTestSuite): void {
    if (mutation_test_suite.project === this.project.pk) {
      let index = this.d_mutation_test_suites.findIndex(
        suite => mutation_test_suite.pk === suite.pk);
      this.d_mutation_test_suites.splice(index, 1);

      this.d_selected_mutation_test_suite_pks.delete(mutation_test_suite.pk);
    }
  }

  update_mutation_test_suites_order_changed(
    project_pk: number, mutation_test_suite_order: number[]
  ): void {
    if (project_pk === this.project.pk) {
      sort_by_ordering(this.d_mutation_test_suites, mutation_test_suite_order);
    }
  }
}

function handle_start_rerun_error(component: RerunSubmissions, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
