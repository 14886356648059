
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Course, HttpError, User } from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import DropdownTypeahead from '@/components/dropdown_typeahead.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import {
  handle_api_errors_async,
  handle_global_errors_async,
  make_error_handler_func
} from '@/error_handling';
import { toggle } from '@/utils';
import { is_non_negative, string_to_num } from '@/validators';

@Component({
  components: {
    APIErrors,
    DropdownTypeahead,
    ValidatedForm,
    ValidatedInput,
  }
})
export default class EditLateDays extends Vue {
  @Prop({required: true, type: Course})
  course!: Course;

  d_loading = true;
  d_searching = false;
  d_saving = false;

  d_students: string[] = [];

  d_selected_username = '';
  d_num_late_days = 0;

  // For showing a helpful message when the user uses the search button
  // and the user doesn't exist.
  d_show_not_found_message = false;

  d_late_day_form_is_valid = false;

  readonly is_non_negative = is_non_negative;
  readonly string_to_num = string_to_num;

  @handle_global_errors_async
  async created() {
    this.d_students = (
      await this.course.get_students()
    ).map(user => user.username).sort((first, second) => first.localeCompare(second));
    this.d_loading = false;
  }

  @handle_global_errors_async
  async search(username: string) {
    username = username.trim();
    if (username === '') {
      return;
    }

    this.d_saving = true;
    this.d_show_not_found_message = false;
    this.d_selected_username = '';
    try {
      this.d_num_late_days = (
        await User.get_num_late_days(this.course.pk, username)
      ).late_days_remaining;
      this.d_selected_username = username;
    }
    catch (e) {
      if (!(e instanceof HttpError) || e.status !== 404) {
        // istanbul ignore next
        throw e;
      }
      this.d_show_not_found_message = true;
      // Do NOT put this line in the finally block
      this.d_selected_username = username;
    }
    finally {
      this.d_saving = false;
    }
  }

  @handle_api_errors_async(make_error_handler_func())
  save_late_days() {
    return toggle(this, 'd_saving', () => {
        return User.set_num_late_days(
          this.course.pk, this.d_selected_username, this.d_num_late_days);
    });
  }
}
