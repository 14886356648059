import { render, staticRenderFns } from "./single_instructor_file.vue?vue&type=template&id=72349968&scoped=true&"
import script from "./single_instructor_file.vue?vue&type=script&lang=ts&"
export * from "./single_instructor_file.vue?vue&type=script&lang=ts&"
import style0 from "./single_instructor_file.vue?vue&type=style&index=0&id=72349968&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72349968",
  null
  
)

export default component.exports