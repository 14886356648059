

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { AGTestCase } from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import AGTestCaseFdbkConfigPanel from '@/components/project_admin/ag_tests/ag_test_case_fdbk_config_panel.vue';
import { FeedbackConfigLabel, FeedbackDescriptions } from '@/components/project_admin/feedback_config_panel/feedback_config_utils';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async } from '@/error_handling';
import { deep_copy } from '@/utils';
import { is_not_empty } from '@/validators';

@Component({
  components: {
    APIErrors,
    AGTestCaseFdbkConfigPanel,
    Tooltip,
    ValidatedForm,
    ValidatedInput
  }
})
export default class AGTestCaseSettings extends Vue {

  @Prop({required: true, type: AGTestCase})
  ag_test_case!: AGTestCase;

  readonly is_not_empty = is_not_empty;
  readonly FeedbackConfigLabel = FeedbackConfigLabel;
  readonly FeedbackDescriptions = FeedbackDescriptions;

  d_ag_test_case: AGTestCase | null = null;
  d_saving = false;
  d_settings_form_is_valid = true;

  @Watch('ag_test_case')
  on_test_case_change(new_test_case: AGTestCase, old_test_case: AGTestCase) {
    this.d_ag_test_case = deep_copy(new_test_case, AGTestCase);
  }

  created() {
    this.d_ag_test_case = deep_copy(this.ag_test_case, AGTestCase);
  }

  @handle_api_errors_async(handle_save_ag_test_case_settings_error)
  async save_ag_test_case_settings() {
    try {
      this.d_saving = true;
      (<APIErrors> this.$refs.api_errors).clear();
      await this.d_ag_test_case!.save();
    }
    finally {
      this.d_saving = false;
    }
  }
}

function handle_save_ag_test_case_settings_error(component: AGTestCaseSettings, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
