
import { Component, Prop, Vue } from 'vue-property-decorator';

import { CorrectnessLevel } from '@/components/project_view/submission_detail/correctness';

@Component
export default class CorrectnessIcon extends Vue {
  @Prop({required: true, type: String})
  correctness_level!: string;

  readonly CorrectnessLevel = CorrectnessLevel;
}
