
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import moment from 'moment-timezone';

import TimePicker from "@/components/datetime/time_picker.vue";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  components: {
    TimePicker
  }
})
export default class DatetimePicker extends Vue {

  @Prop({default: null, type: String})
  value!: string;

  @Watch('value')
  on_value_changed(new_value: string | null, old_value: string | null) {
    this.set_date_and_time(new_value);
  }

  d_date: moment.Moment = moment();
  d_time: string = '12:00';

  private d_is_open = false;

  d_selected_day: number | null = null;
  d_selected_month: number | null = null;
  d_selected_year: number | null = null;

  d_month = moment().month();  // The current month shown on the calendar
  d_year = moment().year();  // The current year shown on the calendar

  d_timezone: string | null = null;

  calendar: number[][] = [
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0]
  ];

  get num_rows() {
    return this.calendar.length;
  }
  get num_cols() {
    return this.calendar[0].length;
  }

  created() {
    this.set_date_and_time(this.value);

    this.d_year = this.d_date.year();
    this.d_month = this.d_date.month();
    this.calculate_days();
  }

  set_date_and_time(value: string | null) {
    if (value === null) {
      this.d_date = moment();
    }
    else {
      this.d_date = moment(value);
      if (!this.d_date.isValid()) {
        throw new InvalidDatetimeStrError(`Invalid datetime string: ${value}`);
      }

      this.d_selected_day = this.d_date.date();
      this.d_selected_month = this.d_date.month();
      this.d_selected_year = this.d_date.year();
    }
    // We only want to guess the timezone the first time this component
    // instance is inintalized, so that when the user changes the timezone
    // it shows their selected datetime in that timezone unless they refresh
    // the page.
    this.d_timezone = this.d_timezone ?? moment.tz.guess();

    this.d_date.tz(this.d_timezone, false);

    this.d_time = moment({hours: this.d_date.hours(),
                          minutes: this.d_date.minutes()}).format('HH:mm');
  }

  get is_visible() {
    return this.d_is_open;
  }

  toggle_visibility() {
    this.d_is_open = !this.d_is_open;
  }

  show() {
    this.d_is_open = true;
  }

  hide() {
    this.d_is_open = false;
  }

  go_to_next_month() {
    if (this.d_month === 11) {
      this.d_year += 1;
    }
    this.d_month = (this.d_month + 1) % this.months.length;
    this.calculate_days();
  }

  go_to_prev_month() {
    if (this.d_month === 0) {
      this.d_month = this.months.length - 1;
      this.d_year -= 1;
    }
    else {
      this.d_month = this.d_month - 1;
    }
    this.calculate_days();
  }

  calculate_days() {
    let first_day = new Date(this.d_year, this.d_month, 1);
    let last_day = new Date(this.d_year, this.d_month + 1, 0);
    let offset = first_day.getDay();
    let day_count = 1;

    for (let row = 0; row < this.num_rows; ++row) {
      for (let col = 0; col < this.num_cols; ++col) {
        if (offset === 0) {
          this.calendar[row][col] = day_count > last_day.getDate() ? 0 : day_count;
          day_count += 1;
        }
        else {
          this.calendar[row][col] = 0;
          offset--;
        }
      }
    }
  }

  update_day_selected(day: number) {
    this.d_selected_day = day;
    this.d_selected_month = this.d_month;
    this.d_selected_year = this.d_year;
    this.d_date = this.d_date.clone().set({
      year: this.d_selected_year,
      month: this.d_selected_month,
      date: this.d_selected_day,
    });
    this.$emit('input', this.d_date.format());
  }

  update_time_selected() {
    let time = moment(this.d_time, 'HH:mm');
    this.d_date = this.d_date.clone().set({hours: time.hours(), minutes: time.minutes()});
    if (this.d_selected_day !== null) {
      this.$emit('input', this.d_date.format());
    }
  }

  update_timezone_selected(event: HTMLInputEvent) {
    this.d_timezone = event.target.value;
    this.d_date = this.d_date.tz(this.d_timezone, true);
    this.$emit('input', this.d_date.format());
  }

  get timezones() {
    return moment.tz.names();
  }

  readonly months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  readonly days_of_the_week = [
    "Su",
    "M",
    "Tu",
    "W",
    "Th",
    "F",
    "Sa"
  ];
}

export class InvalidDatetimeStrError extends Error {
  // See https://github.com/Microsoft/TypeScript/issues/13965
  __proto__: Error; // tslint:disable-line

  constructor(msg?: string) {
    const actual_proto = new.target.prototype;
    super(msg);
    this.__proto__ = actual_proto;
  }
}
