
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import { Course, Semester } from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async } from '@/error_handling';
import { deep_copy, format_datetime_short } from '@/utils';
import {
  is_integer,
  is_non_negative,
  is_not_empty,
  is_number,
  make_min_value_validator,
  string_to_num
} from '@/validators';

export class CourseFormData {
  name: string;
  semester: Semester | null;
  year: number | null;
  subtitle: string;
  num_late_days: number;
  allowed_guest_domain: string;

  constructor({
    name = '',
    semester = Semester.fall,
    year = (new Date()).getFullYear(),
    subtitle = '',
    num_late_days = 0,
    allowed_guest_domain = '',
  }: Partial<CourseFormData> = {}) {
    this.name = name;
    this.semester = semester;
    this.year = year;
    this.subtitle = subtitle;
    this.num_late_days = num_late_days;
    this.allowed_guest_domain = allowed_guest_domain;
  }
}

@Component({
  components: {
    Tooltip,
    ValidatedForm,
    ValidatedInput,
  }
})
export default class CourseForm extends Vue {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  @Prop({default: null, type: [Course, CourseFormData]})
  course!: Course | null;

  d_form_data = new CourseFormData();

  @Watch('course')
  on_course_changed(new_value: Course, old_value: Course) {
    this.d_form_data = new CourseFormData(new_value);
  }

  semesters = [Semester.fall, Semester.winter, Semester.spring, Semester.summer];

  readonly Semester = Semester;

  readonly is_non_negative = is_non_negative;
  readonly is_not_empty = is_not_empty;
  readonly is_integer = is_integer;
  readonly is_number = is_number;
  readonly is_valid_course_year = make_min_value_validator(2000);
  readonly string_to_num = string_to_num;

  readonly format_datetime_short = format_datetime_short;

  created() {
    if (this.course === null) {
      let split_username = this.d_globals.current_user!.username.split('@');
      let current_user_domain = split_username.length === 2 ? '@' + split_username[1] : '';
      this.d_form_data = new CourseFormData({
        allowed_guest_domain: current_user_domain
      });
    }
    else {
      this.d_form_data = new CourseFormData(this.course);
    }

    this.$nextTick(() => {
      (<ValidatedInput> this.$refs.course_name_input).focus();
    });
  }

  submit() {
    this.$emit('submit', this.d_form_data);
  }
}
