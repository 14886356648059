
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop({required: true, type: Number})
  progress!: number;

  get processed_progress() {
    // In some cases (e.g. downloading files), the Content-Length response
    // header is not set properly. In those cases we fall back on the size
    // of the file, which is probably smaller than the size of the
    // full response. For those cases, we cap progress here at 100%.
    return Math.min(100, Math.round(this.progress));
  }
}
