
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  BugsExposedFeedbackLevel,
  MutationTestSuiteFeedbackConfig
} from 'ag-client-typescript';

@Component
export default class EditFeedbackSettingsMutationSuite extends Vue {

  @Prop({required: true, type: String})
  config_name!: string;

  @Prop({required: true, type: Object})
  value!: MutationTestSuiteFeedbackConfig | null;

  d_feedback_config: MutationTestSuiteFeedbackConfig | null = null;
  d_is_open = false;

  readonly BugsExposedFeedbackLevel = BugsExposedFeedbackLevel;

  @Watch('value')
  on_value_changed(new_value: MutationTestSuiteFeedbackConfig,
                   old_value: MutationTestSuiteFeedbackConfig) {
    this.d_feedback_config = JSON.parse(JSON.stringify(new_value));
  }
  toggle_is_open() {
    this.d_is_open = !this.d_is_open;
  }
  created() {
    this.d_feedback_config = JSON.parse(JSON.stringify(this.value));
  }
}
