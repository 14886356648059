
  import { Component, Vue } from 'vue-property-decorator';

  import FileUpload from '@/components/file_upload.vue';

  @Component({
    components: {FileUpload}
  })
  export default class FileUploadDemo extends Vue {
    print_upload_files(files: File[]) {
      console.log(files);
    }
  }

