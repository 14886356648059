
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import { Course, Project, User } from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import APIErrors from "@/components/api_errors.vue";
import Modal from '@/components/modal.vue';
import SelectObject from '@/components/select_object.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async, handle_global_errors_async } from '@/error_handling';
import { format_course_name, toggle } from '@/utils';
import { is_not_empty } from '@/validators';

@Component({
  components: {
    APIErrors,
    Modal,
    SelectObject,
    Tooltip,
    ValidatedForm,
    ValidatedInput
  }
})
export default class SingleProject extends Vue {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  @Prop({required: true, type: Course})
  course!: Course;

  @Prop({required: true, type: Project})
  project!: Project;

  readonly is_not_empty = is_not_empty;
  readonly format_course_name = format_course_name;

  cloning_destinations: Course[] = [];
  course_to_clone_to: Course | null = null;
  cloned_project_name: string = "";
  cloned_project_name_is_valid = false;
  d_show_clone_project_modal = false;
  d_cloning = false;
  course_index: number = 0;

  @handle_global_errors_async
  async created() {
    this.course_to_clone_to = this.course;
    this.cloning_destinations = await this.d_globals.current_user!.courses_is_admin_for();
    this.course_index = this.cloning_destinations.findIndex(
      course => course.pk === this.course.pk);
  }

  async start_clone_dialog() {
    this.cloned_project_name = "";
    this.course_to_clone_to = this.course;
    this.d_show_clone_project_modal = true;
  }

  @handle_api_errors_async(handle_add_cloned_project_error)
  clone_project() {
    return toggle(this, 'd_cloning', async () => {
      let new_project = await this.project.copy_to_course(
        this.course_to_clone_to!.pk, this.cloned_project_name
      );
      (<ValidatedInput> this.$refs.cloned_project_name).reset_warning_state();
      this.d_show_clone_project_modal = false;
    });
  }
}

export function handle_add_cloned_project_error(component: SingleProject, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}

