
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { Criterion } from "ag-client-typescript";

import APIErrors from "@/components/api_errors.vue";
import Modal from "@/components/modal.vue";
import CriterionForm, { CriterionFormData } from "@/components/project_admin/handgrading_settings/criterion_form.vue";
import { handle_api_errors_async } from '@/error_handling';
import { deep_copy, format_datetime, safe_assign } from "@/utils";

@Component({
  components: {
    APIErrors,
    CriterionForm,
    Modal
  }
})
export default class SingleCriterion extends Vue {
  @Prop({type: Criterion})
  criterion!: Criterion;

  d_criterion: Criterion = new Criterion({
    pk: 0,
    handgrading_rubric: 0,
    last_modified: '',
    short_description: '',
    long_description: '',
    points: 0
  });

  d_deleting = false;
  d_delete_modal_is_open = false;

  d_edit_mode = false;
  d_saving = false;
  d_criterion_form_is_valid = false;

  readonly format_datetime = format_datetime;

  created() {
    this.d_criterion = deep_copy(this.criterion, Criterion);
  }

  @Watch('criterion')
  on_criterion_changed(new_val: Criterion, old_val: Criterion) {
    this.d_criterion = deep_copy(new_val, Criterion);
  }

  @handle_api_errors_async(handle_save_criterion_error)
  async save(form_data: CriterionFormData) {
    try {
      this.d_saving = true;
      safe_assign(this.d_criterion, form_data);
      await this.d_criterion.save();
      this.d_edit_mode = false;
    }
    finally {
      this.d_saving = false;
    }
  }

  @handle_api_errors_async(handle_delete_criterion_error)
  async delete_criterion() {
    try {
      this.d_deleting = true;
      await this.d_criterion.delete();
      this.d_delete_modal_is_open = false;
    }
    finally {
      this.d_deleting = false;
    }
  }
}

export function handle_save_criterion_error(component: SingleCriterion, error: unknown) {
  (<APIErrors> component.$refs.save_criterion_errors).show_errors_from_response(error);
}

export function handle_delete_criterion_error(component: SingleCriterion, error: unknown) {
  (<APIErrors> component.$refs.delete_criterion_errors).show_errors_from_response(error);
}

