import { render, staticRenderFns } from "./suite_settings.vue?vue&type=template&id=661e4f09&scoped=true&"
import script from "./suite_settings.vue?vue&type=script&lang=ts&"
export * from "./suite_settings.vue?vue&type=script&lang=ts&"
import style0 from "./suite_settings.vue?vue&type=style&index=0&id=661e4f09&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661e4f09",
  null
  
)

export default component.exports