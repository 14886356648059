
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { InstructorFile } from 'ag-client-typescript';
import * as FileSaver from 'file-saver';

import APIErrors from '@/components/api_errors.vue';
import Modal from '@/components/modal.vue';
import ProgressOverlay from '@/components/progress_overlay.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import {
  handle_api_errors_async,
  handle_global_errors_async,
  make_error_handler_func,
} from '@/error_handling';
import { format_datetime, toggle } from '@/utils';
import { is_not_empty } from '@/validators';

@Component({
  components: {
    APIErrors,
    Modal,
    ProgressOverlay,
    ValidatedForm,
    ValidatedInput
  }
})
export default class SingleInstructorFile extends Vue {
  @Prop({required: true, type: InstructorFile})
  file!: InstructorFile;

  @Prop({required: false, type: Boolean})
  selected_for_deletion!: boolean;

  readonly is_not_empty = is_not_empty;
  readonly format_datetime = format_datetime;

  editing = false;
  new_file_name: string = "";
  new_name_is_valid = true;

  d_download_progress: number | null = null;
  d_downloading = false;

  @handle_api_errors_async(handle_rename_file_error)
  async rename_file() {
    if (this.new_file_name !== this.file.name) {
      await this.file.rename(this.new_file_name);
    }
    this.editing = false;
  }

  cancel_renaming_file() {
    this.editing = false;
  }

  @handle_global_errors_async
  download_file() {
    this.d_download_progress = null;
    return toggle(this, 'd_downloading', async () => {
      let file_content = this.file.get_content((event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.d_download_progress = 100 * (1.0 * event.loaded / event.total);
        }
      });
      FileSaver.saveAs(new File([await file_content], this.file.name));
      this.d_download_progress = null;
    });
  }
}

export function handle_rename_file_error(component: SingleInstructorFile, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
