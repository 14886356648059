
import { Component, Prop, Vue } from 'vue-property-decorator';

import Tooltip from '@/components/tooltip.vue';

@Component({
  components: {Tooltip}
})
export default class TooltipDemo extends Vue {
}
