
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { AGTestCase,
         AGTestCommandFeedbackConfig,
         ValueFeedbackLevel
} from 'ag-client-typescript';

import Toggle from '@/components/toggle.vue';

@Component({
  components: {
    Toggle
  }
})
export default class AGTestCommandAdvancedFdbkSettings extends Vue {
  @Prop({required: false, type: Object})
  value!: AGTestCommandFeedbackConfig | null;

  @Prop({required: true, type: AGTestCase})
  ag_test_case!: AGTestCase;

  d_feedback_config: AGTestCommandFeedbackConfig | null = null;
  d_is_open = false;

  readonly ValueFeedbackLevel = ValueFeedbackLevel;

  @Watch('value')
  on_value_changed(new_value: AGTestCommandFeedbackConfig,
                   old_value: AGTestCommandFeedbackConfig) {
    this.d_feedback_config = JSON.parse(JSON.stringify(new_value));
  }

  created() {
    this.d_feedback_config = JSON.parse(JSON.stringify(this.value));
  }

  toggle_is_open() {
    this.d_is_open = !this.d_is_open;
  }
}
