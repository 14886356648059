
import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/components/modal.vue';

@Component({
  components: { Modal }
})
export default class ModalDemo extends Vue {
    show_modal_1 = false;
    show_modal_2 = false;
    show_modal_3 = false;
    show_modal_4 = false;
    show_modal_5 = false;
    show_modal_6 = false;
    show_modal_7 = false;
    show_modal_overflow = false;
}
